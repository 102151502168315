export const colors = {
  gray: '#868586',
  lightgray: '#86858678',
  linegray: '#818182',
  blue: '#1e74a3',
  lightblue: '#3ab6ec',
  deepblue: '#003459',
  sky: '#f0f5f8',
  darkblue: '#114062',
  green: '#2ec35f',
};
