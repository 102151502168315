/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    POSExtraItem,
    POSExtraItemFromJSON,
    POSExtraItemFromJSONTyped,
    POSExtraItemToJSON,
    POSQuantity,
    POSQuantityFromJSON,
    POSQuantityFromJSONTyped,
    POSQuantityToJSON,
} from './';

/**
 * 
 * @export
 * @interface Basket
 */
export interface Basket {
    /**
     * 
     * @type {number}
     * @memberof Basket
     */
    readonly id?: number;
    /**
     * 
     * @type {Array<POSQuantity>}
     * @memberof Basket
     */
    itemList: Array<POSQuantity>;
    /**
     * 
     * @type {string}
     * @memberof Basket
     */
    readonly amount?: string;
    /**
     * 
     * @type {string}
     * @memberof Basket
     */
    readonly dollarAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Basket
     */
    readonly discount?: string;
    /**
     * 
     * @type {string}
     * @memberof Basket
     */
    readonly dollarDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof Basket
     */
    readonly amountWithDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof Basket
     */
    readonly dollarAmountWithDiscount?: string;
    /**
     * 
     * @type {Array<POSExtraItem>}
     * @memberof Basket
     */
    extraItems: Array<POSExtraItem>;
}

export function BasketFromJSON(json: any): Basket {
    return BasketFromJSONTyped(json, false);
}

export function BasketFromJSONTyped(json: any, ignoreDiscriminator: boolean): Basket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'itemList': ((json['item_list'] as Array<any>).map(POSQuantityFromJSON)),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'dollarAmount': !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'dollarDiscount': !exists(json, 'dollar_discount') ? undefined : json['dollar_discount'],
        'amountWithDiscount': !exists(json, 'amount_with_discount') ? undefined : json['amount_with_discount'],
        'dollarAmountWithDiscount': !exists(json, 'dollar_amount_with_discount') ? undefined : json['dollar_amount_with_discount'],
        'extraItems': ((json['extra_items'] as Array<any>).map(POSExtraItemFromJSON)),
    };
}

export function BasketToJSON(value?: Basket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_list': ((value.itemList as Array<any>).map(POSQuantityToJSON)),
        'extra_items': ((value.extraItems as Array<any>).map(POSExtraItemToJSON)),
    };
}


