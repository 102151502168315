/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderApplyCoupon
 */
export interface OrderApplyCoupon {
    /**
     * 
     * @type {string}
     * @memberof OrderApplyCoupon
     */
    coupon: string;
    /**
     * 
     * @type {string}
     * @memberof OrderApplyCoupon
     */
    order: string;
}

export function OrderApplyCouponFromJSON(json: any): OrderApplyCoupon {
    return OrderApplyCouponFromJSONTyped(json, false);
}

export function OrderApplyCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderApplyCoupon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coupon': json['coupon'],
        'order': json['order'],
    };
}

export function OrderApplyCouponToJSON(value?: OrderApplyCoupon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coupon': value.coupon,
        'order': value.order,
    };
}


