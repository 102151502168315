import styled from 'styled-components';

export const Box = styled.div`
  width: ${({ full }) => (full ? '100%' : null)};

  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  flex-direction: ${({ col }) => (col ? 'column' : null)};
  align-items: ${({ align }) => (align ? 'center' : null)};
  justify-content: ${({ justify }) => (justify ? justify : null)};

  @media screen and (max-width: 999px) {
    flex-direction: column;
  }

  & form {
    @media screen and (max-width: 999px) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: 720px) {
      align-items: center;
    }
    & label {
      display: none;
    }
    & input {
      color: white;
      border-bottom: 2px solid white;
      ::placeholder,
      ::-webkit-input-placeholder {
        color: white;
        text-align: center;
        font-size: 18px;
      }
      :-ms-input-placeholder {
        color: white;
        text-align: center;
        font-size: 18px;
      }
    }
    & input:focus {
      color: white;
    }
  }

  padding-top: ${({ pt }) => pt};
  padding-bottom: ${({ pb }) => pb};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};

  margin-tom: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  margin-right: ${({ mr }) => mr};
`;

export const ContainerFull = styled.div`
  flex-direction: ${({ col }) => (col ? 'column' : 'row')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bg }) => (bg ? bg : null)};
  width: 100%;

  padding-top: ${({ pt }) => (pt ? pt : '6rem')};
  padding-bottom: ${({ pb }) => (pb ? pb : '2rem')};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};

  margin-top: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  margin-right: ${({ mr }) => mr};
  @media screen and (max-width: 820px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const StyledInput = styled.input`
  text-align: center;
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  padding-bottom: 0.5rem;
  font-size: 18px;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;

  @media screen and (max-width: 720px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &:focus {
    outline: none;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
  }
  :-ms-input-placeholder {
    color: white;
  }
`;

export const StyledParagraph = styled.p`
  display: ${({ flex }) => (flex ? 'flex' : null)};
  color: ${({ color }) => color};
  font-size: ${({ fsz }) => fsz};
  font-weight: ${({ fw }) => fw};
  padding-top: ${({ pt }) => pt};
  padding-bottom: ${({ pb }) => pb};
  padding-left: ${({ pl }) => pl};
  padding-right: ${({ pr }) => pr};

  margin-tom: ${({ mt }) => mt};
  margin-bottom: ${({ mb }) => mb};
  margin-left: ${({ ml }) => ml};
  margin-right: ${({ mr }) => mr};

  margin-right: 1rem;
  @media screen and (max-width: 820px) {
    text-align: center;
  }
`;
