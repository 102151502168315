import React, { useEffect, useState } from 'react';

import { useAuth } from 'hooks';
import { useProgressiveImage } from 'hooks/useProgressiveImage';

import { Helmet } from 'react-helmet';
import { Header } from 'components/Header';

import Intro from 'pages/LeatherCare/Intro/Intro';
import { HIW } from './HIW/HIW';
// import PickTheBest from 'components/PricingNew/PickTheBest/PickTheBest';
// import CleaningPrices from './CleaningPrices/CleaningPrices';
// import GownCalculator from './GownCalculator/GownCalculator';
// import DiscountInfo from 'pages/LeatherCare/DiscountInfo/DiscountInfo';
import InputArea from 'components/PricingNew/InputArea/InputArea';
// import PickUpInfo from 'pages/LeatherCare/PickUpInfo/PickUpInfo';
// import Sheduling from 'pages/LeatherCare/Sheduling/Sheduling';
// import FAQ from 'pages/LeatherCare/FAQ/FAQ';
// import HaveMoreQuestion from 'components/PricingNew/HaveMoreQuestions/HaveMoreQuestions';

import { BgRemover, MainContainer, NavbarWrapper, RedesignInputArea } from 'pages/LeatherCare/styles';
import { CenteredTextWrapper, IntroWrapper, PaddingRemover, PresBr } from './Preservation.style';
import { CustomBr } from './Steps/Steps.style';

import IntroImage from '../../assets/new-ui/preservation/preservation_intro-min.png';
import { PreservationQuestions } from './FAQ';

const LazySteps = React.lazy(() => import('./Steps/Steps'));
const LazyPickTheBest = React.lazy(() => import('components/PricingNew/PickTheBest/PickTheBest'));
const LazyCleaningPrices = React.lazy(() => import('./CleaningPrices/CleaningPrices'));
const LazyGownCalculator = React.lazy(() => import('./GownCalculator/GownCalculator'));
const LazyDiscountInfo = React.lazy(() => import('pages/LeatherCare/DiscountInfo/DiscountInfo'));
const LazyPickUpInfo = React.lazy(() => import('pages/LeatherCare/PickUpInfo/PickUpInfo'));
const LazySheduling = React.lazy(() => import('pages/LeatherCare/Sheduling/Sheduling'));
const LazyFAQ = React.lazy(() => import('pages/LeatherCare/FAQ/FAQ'));
const LazyHaveMoreQuestion = React.lazy(() => import('components/PricingNew/HaveMoreQuestions/HaveMoreQuestions'));

const PreservationPage = ({ flag }) => {
  const { user, logout } = useAuth();
  const [navbarColor, setNavbarColor] = useState('transparent');
  const [navbarLinksColor, setNavbarLinksColor] = useState('white');
  useEffect(() => {
    if (flag && navbarColor !== 'white') {
      setNavbarColor('white');
      setNavbarLinksColor('#003459');
    }
    if (!flag && navbarColor !== 'transparent') {
      setNavbarColor('transparent');
      setNavbarLinksColor('white');
    }
  }, [flag, navbarColor]);
  const loadedImg = useProgressiveImage(IntroImage);
  return (
    <>
      <Helmet>
        <title>Washmix - Preservation</title>
        <meta name="description" content="Preservation" />
      </Helmet>
      <NavbarWrapper bg={navbarColor} linksColor={navbarLinksColor}>
        <Header logout={logout} user={user} />
      </NavbarWrapper>
      <MainContainer>
        <PaddingRemover>
          <Intro background={loadedImg}>
            <IntroWrapper>
              <h1>
                Gown Cleaning <PresBr m /> & Preservation
              </h1>
              <h3>
                We <strong>Process, Clean</strong> & <strong>Preserve</strong>
              </h3>
              <h3>
                one of life&apos;s <CustomBr sm /> most precious
              </h3>
              <h3>Mommets</h3>
              <p>
                All with door-to-door Pickup & Delivery <br /> [Home & Office]
              </p>
            </IntroWrapper>
          </Intro>
        </PaddingRemover>

        <HIW />
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySteps />
        </React.Suspense>
        {/* <Steps /> */}
        <BgRemover bg="white">
          {/* <PickTheBest /> */}
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyPickTheBest />
          </React.Suspense>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyCleaningPrices />
          </React.Suspense>
          {/* <CleaningPrices /> */}
        </BgRemover>
        <BgRemover bg="#f0f5f8">
          <CenteredTextWrapper>
            <h1>Wedding Gown</h1>
          </CenteredTextWrapper>
          {/* <GownCalculator /> */}
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyGownCalculator />
          </React.Suspense>
        </BgRemover>
        {/* <DiscountInfo /> */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyDiscountInfo />
        </React.Suspense>
        <InputArea />
        {/* <PickUpInfo title="HOW IT WORKS" /> */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyPickUpInfo title="HOW IT WORKS" />
        </React.Suspense>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySheduling title="Schedule" />
        </React.Suspense>
        {/* <Sheduling title="Schedule" /> */}
        {/* <FAQ title="FAQ" questions={PreservationQuestions} /> */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyFAQ title="FAQ" questions={PreservationQuestions} />
        </React.Suspense>
        <RedesignInputArea>
          <InputArea />
        </RedesignInputArea>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyHaveMoreQuestion />
        </React.Suspense>
        {/* <HaveMoreQuestion /> */}
      </MainContainer>
    </>
  );
};

export default PreservationPage;
