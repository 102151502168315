import { colors } from 'pages/LeatherCare/colors';
import styled from 'styled-components';

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h1,
  h3,
  p {
    color: ${colors.gray};
    margin: 0;
  }
  h3 {
    font-weight: 400;
    strong {
      color: ${colors.darkgray};
    }
  }
  h1 {
    margin-bottom: 1rem;
    color: ${colors.darkgray};
  }
  p {
    margin-top: 1rem;
  }
`;

export const CenteredTextWrapper = styled.div`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
  h1 {
    color: ${colors.darkblue};
  }
`;

export const PaddingRemover = styled.div`
  section:first-of-type {
    @media screen and (max-width: 999px) {
      padding-bottom: 3rem;
    }
  }
`;

export const PresBr = styled.br`
  display: ${({ m }) => (m ? 'none' : null)};
  @media screen and (max-width: 999px) {
    display: ${({ m }) => (m ? 'block' : null)};
  }
`;
