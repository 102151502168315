import styled from 'styled-components';

export const HamburgerInner = styled.div`
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: ${({ burgerColor }) => (burgerColor ? `0` : '#003459;')};
  border-radius: 2px;
  transition-duration: 75ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  top: 50%;
  display: block;
  margin-top: -2px;
  transform: ${({ isActive }) => (isActive ? `rotate(-45deg)` : null)};
  ::before {
    content: '';
    position: absolute;
    display: block;
    /* background-color: #003459; */
    background-color: ${({ burgerColor }) => (burgerColor ? `0` : '#003459;')};
    height: 2px;
    width: 24px;
    top: -8px;
    border-radius: 2px;
    -webkit-transition: top 75ms ease 0.12s, opacity 75ms ease;
    transition: top 75ms ease 0.12s, opacity 75ms ease;
    opacity: ${({ isActive }) => (isActive ? `0` : 1)};
  }
  ::after {
    content: '';
    position: absolute;
    display: block;
    background-color: ${({ burgerColor }) => (burgerColor ? `0` : '#003459;')};
    height: 2px;
    width: 24px;
    bottom: -8px;
    border-radius: 2px;
    transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: bottom 75ms ease 0.12s, -webkit-transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    -webkit-transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform: ${({ isActive }) => (isActive ? `rotate(-90deg)` : null)};
    bottom: ${({ isActive }) => (isActive ? `0` : null)};
    transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
  }
`;
