/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateIntent
 */
export interface CreateIntent {
    /**
     * 
     * @type {boolean}
     * @memberof CreateIntent
     */
    isSaveCard: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateIntent
     */
    order?: string;
}

export function CreateIntentFromJSON(json: any): CreateIntent {
    return CreateIntentFromJSONTyped(json, false);
}

export function CreateIntentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIntent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSaveCard': json['is_save_card'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function CreateIntentToJSON(value?: CreateIntent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'is_save_card': value.isSaveCard,
        'order': value.order,
    };
}


