import React from 'react';
import { Container, IntroWrapper } from './Intro.styles';
const Intro = ({ children, background }) => {
  return (
    <Container background={background}>
      <IntroWrapper>{children}</IntroWrapper>
    </Container>
  );
};

export default Intro;
