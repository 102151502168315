/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    address: number;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    readonly pickupStart?: string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    readonly pickupEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    readonly dropoffDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    readonly dropoffStart?: string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    readonly dropoffEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Request
     */
    isRush?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    readonly schedule?: number;
}

export function RequestFromJSON(json: any): Request {
    return RequestFromJSONTyped(json, false);
}

export function RequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): Request {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'address': json['address'],
        'pickupDate': json['pickup_date'],
        'pickupStart': !exists(json, 'pickup_start') ? undefined : json['pickup_start'],
        'pickupEnd': !exists(json, 'pickup_end') ? undefined : json['pickup_end'],
        'dropoffDate': !exists(json, 'dropoff_date') ? undefined : json['dropoff_date'],
        'dropoffStart': !exists(json, 'dropoff_start') ? undefined : json['dropoff_start'],
        'dropoffEnd': !exists(json, 'dropoff_end') ? undefined : json['dropoff_end'],
        'isRush': !exists(json, 'is_rush') ? undefined : json['is_rush'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
    };
}

export function RequestToJSON(value?: Request | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'pickup_date': value.pickupDate,
        'is_rush': value.isRush,
        'comment': value.comment,
    };
}


