import React, { useCallback } from 'react';

import Button from 'components/Button/Button';

import { colors } from '../colors';
import { Box, ContainerFull, StyledInput, StyledParagraph } from './InputArea.styles';
import { useAuth } from 'hooks';
import { useModal } from 'modals';
import { AddressValues, GooglePlaces } from 'components/GooglePlaces';
import { useQuery } from 'react-query';
import { getZipCodes } from 'services/apiClient';
import { useHistory } from 'react-router-dom';
const InputArea = () => {
  const { setZipCode, setAddressLine1 } = useAuth();
  const { openModal } = useModal();
  const { data: zipCodes } = useQuery('locations', getZipCodes);
  const history = useHistory();
  const handleSubmit = useCallback(
    (values: AddressValues) => {
      setZipCode(values.zipCode);
      setAddressLine1(values.addressLine1);
      if (zipCodes && zipCodes.includes(values.zipCode)) {
        history.push('/registration');
      } else {
        openModal('NOTIFY', values);
      }
    },
    [history, openModal, setAddressLine1, setZipCode, zipCodes],
  );
  return (
    <ContainerFull bg={colors.deepblue} pt="0" pb="0">
      <Box pt="3rem" pb="3rem" flex align>
        <StyledParagraph color="white">See if we service your area</StyledParagraph>
        {/* <StyledInput placeholder="Address" /> */}
        <GooglePlaces onSubmit={handleSubmit} placeholder="Address" />
        {/* <Button title="REQUEST PICKUP →" /> */}
      </Box>
    </ContainerFull>
  );
};

export default InputArea;
