/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionCheckout
 */
export interface SubscriptionCheckout {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionCheckout
     */
    order: string;
}

export function SubscriptionCheckoutFromJSON(json: any): SubscriptionCheckout {
    return SubscriptionCheckoutFromJSONTyped(json, false);
}

export function SubscriptionCheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionCheckout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': json['order'],
    };
}

export function SubscriptionCheckoutToJSON(value?: SubscriptionCheckout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': value.order,
    };
}


