/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    stripeId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    last: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    expirationMonth: number;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    expirationYear: number;
    /**
     * 
     * @type {boolean}
     * @memberof Card
     */
    readonly isActive?: boolean;
}

export function CardFromJSON(json: any): Card {
    return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Card {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'stripeId': json['stripe_id'],
        'last': json['last'],
        'expirationMonth': json['expiration_month'],
        'expirationYear': json['expiration_year'],
        'isActive': !exists(json, 'is_active') ? undefined : json['is_active'],
    };
}

export function CardToJSON(value?: Card | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stripe_id': value.stripeId,
        'last': value.last,
        'expiration_month': value.expirationMonth,
        'expiration_year': value.expirationYear,
    };
}


