import React from 'react';
import './IntroWrapper.scss';
import heroimage from '../../../assets/new-ui/pricing/pricingHero-min.png';

const IntroWrapper = () => {
  return (
    <div className="navbar__wrapper">
      <div className="img__container">
        <img src={heroimage} alt="preview" loading="lazy" />
      </div>
      <div className="navbar__intro">
        <h1>Get up to 20% Discount</h1>
        <h3>With WashMix Credits</h3>
        <div className="navbar__intro__removed">
          <div className="intro__removed__text">
            <p>
              No Monthly <br /> Plans
            </p>
          </div>
          <div className="intro__removed__text">
            <p>
              No Expiration <br /> Date
            </p>
          </div>
          <div className="intro__removed__text">
            <p>
              No <br /> Fees
            </p>
          </div>
        </div>
        <div className="navbar__intro__text">
          <p>
            <strong>Purchase the desire credit,</strong> put toward any desired service and get the <br />
            exclusive discounts and ton of perks.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntroWrapper;
