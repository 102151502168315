import React from 'react';
import {
  ArrowHorizontalWrapper,
  ArrowVerticalWrapper,
  BlueContainer,
  CardItem,
  CardsWrapper,
  Container,
  ListItem,
  ListWrapper,
  WhiteContainer,
  WhiteLeft,
  WhiteRight,
  WhiteWrapper,
} from './HowItWorks.styles';

// import variants from '../../../assets/new-ui/how-it-works/variants.svg';
import heroimage from '../../../assets/new-ui/wash-and-fold/variants.svg';
import arrow from '../../../assets/new-ui/wash-and-fold/arrow.svg';
import Car from '../../../assets/new-ui/wash-and-fold/car.svg';
import Switch from '../../../assets/new-ui/wash-and-fold/switch.svg';
import HomeClothes from '../../../assets/new-ui/wash-and-fold/home-clothes.svg';

const HowItWorks = () => {
  return (
    <Container>
      <WhiteContainer>
        <WhiteWrapper>
          <ArrowHorizontalWrapper>
            <img src={arrow} alt="line-arrow" loading="lazy" />
          </ArrowHorizontalWrapper>
          <WhiteLeft>
            <h1>Types of Leather</h1>
            <h3>
              Synthetic Leather vs. <br />
              Genuine Leather
            </h3>
            <p>
              <strong>Types of Leather</strong> Synthetic Leather vs. Genuine Leather
            </p>
            <ListWrapper>
              <ListItem mb="1rem">
                <strong>Synthetic leather </strong>
                is usually made from polyester material or similar, and therefore may not require full leather care &
                protection.
              </ListItem>
              <ListItem mb="1rem">
                <strong>Genuine leather </strong>
                however, must undergo through full leather care, conditioning and protection.
              </ListItem>
              <ListItem mb="1rem">
                <strong>Suede </strong>
                is also a form of leather that has not been post-theated and thus it is considered as the most sensitive
                form of leather. Not all suede can be processed and due to theit delicate nature will require a
                different process than most other forms of leather.
              </ListItem>
            </ListWrapper>
            <p>
              <strong>Darker- </strong>color leathers may require multiple and more <br /> cycles of pre-treatment than
              lighter ones; but <strong>lighter-</strong>color leathers are more complex and delicate to process.
            </p>
          </WhiteLeft>
          <WhiteRight>
            <img src={heroimage} alt="variant" loading="lazy" />
          </WhiteRight>
          <ArrowVerticalWrapper>
            <img src={arrow} alt="line-arrow" loading="lazy" />
          </ArrowVerticalWrapper>
        </WhiteWrapper>
      </WhiteContainer>
      <BlueContainer>
        <h1>How It Works</h1>
        <CardsWrapper>
          <CardItem>
            <img src={Car} alt="car" loading="lazy" />
            <h1>Pick up</h1>
            <ListItem>
              Signup and <strong>REQUEST A PICKUP</strong>
            </ListItem>
            <ListItem>
              Gather up your clothes and{' '}
              <strong>
                fill <br /> up your bag
              </strong>
            </ListItem>
            <ListItem>Leave your bag out for pickup</ListItem>
          </CardItem>
          <CardItem>
            <img src={Switch} alt="switch" loading="lazy" />
            <h1>Processing</h1>
            <ListItem>
              Your order will be processed by <br />
              us in
              <strong> our processing center</strong>
            </ListItem>
          </CardItem>
          <CardItem>
            <img src={HomeClothes} alt="home" loading="lazy" />
            <h1>Delivery</h1>
            <p>
              When your order is complete and <br />
              delivered - You{`&apos`}ll get an{' '}
              <strong>
                automated <br /> confirmation
              </strong>
              via <strong>TEXT</strong> to your cellphone.
            </p>
          </CardItem>
        </CardsWrapper>
      </BlueContainer>
    </Container>
  );
};

export default HowItWorks;
