import styled from 'styled-components';
import IntroImage from '../../../assets/new-ui/wash-and-fold/intro.png';
export const Container = styled.div`
  /* background-image: url(${IntroImage}); */
  /* background: #4195b8; */
  ${({ background }) => (background ? `background: url(${background})` : `background-color: #4195b8`)};
  /* background: ${({ background }) => (background ? `url(${background})` : null)}; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 999px) {
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;
export const IntroWrapper = styled.section`
  padding-top: 15rem;
  padding-bottom: 15rem;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: 800px;
  text-align: center;
  @media screen and (max-width: 999px) {
    padding-bottom: 30rem;
  }
  @media screen and (max-width: 480px) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 16rem;
    padding-top: 10rem;
  }
  h1,
  h2,
  h3,
  p {
    color: white;
  }
  h2 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
  }
`;
