import { colors } from 'pages/LeatherCare/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 5rem;
  @media screen and (max-width: 999px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 840px;
  width: 100%;
`;

export const Button = styled.button`
  border: none;
  display: flex;
  background: transparent;
  font-size: 30px;
  @media screen and (max-width: 480px) {
    max-width: 20px;
    padding: 0;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.skyblue};
  margin-bottom: 20px;
  padding: 1.5rem;
  border-radius: 20px;
`;
export const ItemQuestionWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;
export const ItemQuestion = styled.div`
  display: flex;
  font-weight: bold;
`;
export const ItemButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  padding-left: 1rem;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0;
  }
`;
export const ItemAnswerWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  padding-right: 3rem;
  @media screen and (max-width: 480px) {
    padding-right: 0;
  }
`;
