export const GeneralFAQQuestions = [
  {
    id: 1,
    question: 'What is Wash & Fold Services? What is Laundry?',
    answer:
      'Wash & Fold and Laundry are very similar, with the main difference being that “Laundry” items are individually processed, cleaned, PRESSED, and charged individually (i.e. one laundry shirt = $6.95) vs. in Wash & Fold, items are washed and folded [NOT Pressed]. Additionally, the order is charged by volume [Per Bag].',
  },
  {
    id: 2,
    question: 'What’s the difference between Laundry & Dry Clean for Men Dress shirts?',
    answer: `Most items are in the Dry Clean category and it is typically on fabrics such as Silk, Linen or Shirts made with delicate fabric that require Dry Cleaning and Hand-Press. Or those that cannot undergo machine press or are smaller in size that can’t be machine pressed. All such items are in the Dry Cleaning and/or hand press category. Some prefer to have their shirts Dry Cleaned to preserve the color and texture and overall design, look and feel of the garment. Laundry on the other hand is typically on button-down, 100% Cotton Shirts that are washed (water + detergent), and machine pressed. Wash & Fold is also in this category (Laundry), however without press as the items are folded - not pressed. Moreover, some shirts with detail, such as snap-on buttons, wooden buttons, silk overall or around collar and sleeves etc, will require hand-press and as a result will be in the dry clean category. If a shirt can NOT undergo wash and Machine Press [by the Press unit], we will proceed with Wash or Dry Clean AND hand-press [with flat/hot iron] to avoid any damages, and charges will reflect in your account as Dry Cleaned item(s). Dry Clean items can not and should not undergo the laundry process. Otherwise it will deteriorate the garment and its fabric and puts it at risk for damage (i.e. color run, permanent damage to fabric, texture, color etc).`,
  },
  {
    id: 3,
    question: 'Are we required to separate Dry Clean and Laundry?',
    answer:
      'NO! For your convenience any items that will require professional cleaning (Laundry or Dry Clean) AND Press can be mixed in the provided Laundry-Counter bags without the need for separation. Our team of professionals will sort out according to the best method and practice of cleaning that is recommended for each garment. Please NOTE: WASH & FOLD is separate and different than Laundry. Wash & Fold MUST be in separate bag. Wash & Fold are mostly used for undergarments, Sheets and towels and charged per load and are NOT pressed.',
  },
  {
    id: 4,
    question: 'How Does Alteration Work? What kind of Alteration Services does WashMix provide?',
    answer:
      'A: With a few exceptions, we do most alteration and repair works. Including alterations on household items, leather, gowns etc. In order to make the process easy, simply fill up the provided card (ALTERATIONS & REPAIR) or attach notes to garments you need altered with specific information (i.e. Hem the blue, Levi’s Jean by 2 inches — original cut).',
  },
  {
    id: 5,
    question: 'How do I sending in my order [the first time]?',
    answer: `For your first order: use any bag you have. Be sure to attach a note [inside] with your account detail [i.e. Name + Address + Phone #]. We will provide you with your own unique laundry bag and essentials for your next order. [NOTE: All Dry Clean and Laundry WITH PRESS must go into one bag - while IF you have WASH & FOLD they must be placed in a separate bag. Wash & Fold are mostly used for undergarments, Sheets and towels and charged per load and are NOT pressed].`,
  },
  {
    id: 6,
    question: 'Is it important to use my Cell Phone number when signing up?',
    answer: `YES! WashMix communicates with its customers via SMS/Text. It’s important that you use a cell phone number, which allows you to send and receive text messages.`,
  },
  {
    id: 7,
    question: 'I live in a secure building, how do I give access to the WashMix team to access my unit?',
    answer: `Many buildings have a system in place to allow vendors easy access to the building or your unit. For example, entry Code [i.e: #1234 + Enter]. IF there are special instructions, be sure to include them when registering for your account. Remember, you are NOT required to be present for pickup or drop off.`,
  },
];
