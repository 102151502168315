/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    kind?: CustomerKindEnum;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'kind': !exists(json, 'kind') ? undefined : json['kind'],
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone': value.phone,
        'full_name': value.fullName,
        'zip_code': value.zipCode,
        'address': value.address,
        'kind': value.kind,
    };
}

/**
* @export
* @enum {string}
*/
export enum CustomerKindEnum {
    Interested = 'interested',
    Possible = 'possible',
    Storage = 'storage'
}


