import { CardsWrapper, Container } from 'pages/LeatherCare/HowItWorks/HowItWorks.styles';

import React from 'react';

import arrow from '../../../assets/new-ui/wash-and-fold/arrow.svg';
// import dresses from '../../../assets/new-ui/preservation/dresses.svg';
import Rug from '../../../assets/new-ui/area-rug/RugBig.svg';
import Car from '../../../assets/new-ui/wash-and-fold/car.svg';
import Switch from '../../../assets/new-ui/wash-and-fold/switch.svg';
import HomeClothes from '../../../assets/new-ui/preservation/empty_home.svg';
import {
  ArrowHorizontalWrapper,
  ArrowVerticalWrapper,
  BlueContainer,
  // ExtraWrapper,
  WhiteContainer,
  WhiteLeft,
  WhiteRight,
  WhiteWrapper,
  ListItem,
  CardItem,
} from './HIW.style';
export const HIW = () => {
  return (
    <Container>
      <WhiteContainer>
        <WhiteWrapper>
          <ArrowHorizontalWrapper>
            <img src={arrow} alt="line-arrow" loading="lazy" />
          </ArrowHorizontalWrapper>
          <WhiteLeft>
            <div>
              <h1>About Your Rug</h1>
              <p>
                The usual settling of dust can be abrasive and will shorten the life of your rug, and in order to avoid
                the abrasiveness of your oriental rugs a good ancient practice is to rotate it every 4-6 months so your
                rug gets even amount of light and traffic and wear throughout.
              </p>
              <p>
                However, cleaning your rug at least once every 2 years by a professional is a very important practice
                that will save your rug and extend its natural life.
              </p>
            </div>
          </WhiteLeft>
          <WhiteRight>
            <img src={Rug} alt="variant" loading="lazy" />
          </WhiteRight>
          <ArrowVerticalWrapper>
            <img src={arrow} alt="line-arrow" loading="lazy" />
          </ArrowVerticalWrapper>
        </WhiteWrapper>
      </WhiteContainer>
      <BlueContainer>
        <h1>How It Works</h1>
        <CardsWrapper>
          <CardItem>
            <img src={Car} alt="car" loading="lazy" />
            <h1>PICK UP</h1>
            <ListItem>
              Signup and <strong>REQUEST A PICKUP</strong>
            </ListItem>
            <ListItem>Roll up your Rug and make ready for pickup</ListItem>
            <ListItem>Place rolled up rug out for pickup</ListItem>
            <ListItem>
              [or <strong>shcedule</strong> a time frame for <br />
              us to pickup]
            </ListItem>
          </CardItem>
          <CardItem>
            <img src={Switch} alt="switch" loading="lazy" />
            <h1>PROCESSING</h1>
            <ListItem>
              Your order will be processed by <br />
              us in
              <strong> our processing center</strong>
            </ListItem>
          </CardItem>
          <CardItem>
            <img src={HomeClothes} alt="home" loading="lazy" />
            <h1>DELIVERY</h1>
            <p>
              When your order is complete and <br />
              delivered - You&apos;ll get an{' '}
              <strong>
                automated <br /> confirmation
              </strong>
              via <strong>TEXT</strong> to your cellphone.
            </p>
          </CardItem>
        </CardsWrapper>
      </BlueContainer>
    </Container>
  );
};
