import { colors } from 'pages/LeatherCare/colors';
import styled from 'styled-components';
// import { colors } from '../colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const WhiteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 999px) {
    position: relative;
  }
`;

export const WhiteWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
  @media screen and (max-width: 999px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 999px) {
    padding-top: 8rem;
  }
`;

export const ArrowHorizontalWrapper = styled.div`
  position: absolute;
  width: 300px;
  left: 300px;
  top: 50px;
  @media screen and (max-width: 999px) {
    transform: rotate(-90deg);
    left: -5%;
    width: 200px;
    top: 20px;
  }
  @media screen and (max-width: 480px) {
    left: -70px;
    width: 140px;
    top: 50px;
  }
`;

export const ArrowVerticalWrapper = styled.div`
  position: absolute;
  img {
    transform: rotate(90deg);
  }
  width: 300px;
  left: 60%;
  bottom: -60px;
  @media screen and (max-width: 999px) {
    width: 200px;
    left: 80%;
  }
  @media screen and (max-width: 480px) {
    width: 170px;
    left: 70%;
  }
`;

export const WhiteLeft = styled.div`
  width: 38%;
  margin-right: 2rem;
  @media screen and (max-width: 999px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    width: 100%;
    h1,
    h3,
    p {
      text-align: center;
    }
  }
  @media screen and (max-width: 999px) {
    p {
      max-width: 70%;
    }
  }
  h1 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 28px;
    color: ${colors.darkblue};
  }
  h3 {
    color: ${colors.darkblue};
    font-weight: 400;
    @media screen and (max-width: 999px) {
      br {
        display: none;
      }
    }
  }
  p {
    color: ${colors.gray};
    @media screen and (max-width: 480px) {
      max-width: 100%;
      text-align: center;
      br {
        display: none;
      }
    }
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  @media screen and (max-width: 999px) {
    max-width: 60%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 480px) {
    max-width: 100%;
    padding-left: 0;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  :before {
    content: '-';
    position: absolute;
    margin-left: -10px;
  }
  color: ${({ color }) => (color ? color : colors.gray)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  @media screen and (max-width: 999px) {
    text-align: center;
  }
`;

export const WhiteRight = styled.div`
  width: 50%;
  display: flex;
  img {
    width: 95%;
    margin-left: auto;
  }
  @media screen and (max-width: 999px) {
    position: absolute;
    width: auto;
    top: -250px;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 999px) {
    top: -330px;
    img {
      width: 470px;
    }
  }
  @media screen and (max-width: 480px) {
    top: -130px;
    img {
      width: 270px;
    }
  }
`;

export const BlueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background-color: #f5f4f5;
  & h1 {
    color: ${colors.darkblue};
  }
  @media screen and (max-width: 999px) {
    padding-bottom: 1rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 2rem;
  width: 100%;
  max-width: 1100px;
  @media screen and (max-width: 999px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  img {
    height: 70px;
  }
  h1 {
    margin-top: 2rem;
    text-align: center;
    font-size: 22px;
    color: ${colors.lightblue};
  }
  p {
    color: ${colors.gray};
  }
  @media screen and (max-width: 999px) {
    margin-bottom: 4rem;
  }
`;

export const DisclaimerHIW = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 3rem;
  padding: 0 1rem;
  h6 {
    color: ${colors.green};
  }
  p {
    color: ${colors.gray};
  }
`;
