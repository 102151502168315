/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WelcomeCheckoutBillingAddress
 */
export interface WelcomeCheckoutBillingAddress {
    /**
     * 
     * @type {string}
     * @memberof WelcomeCheckoutBillingAddress
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof WelcomeCheckoutBillingAddress
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof WelcomeCheckoutBillingAddress
     */
    addressLine2?: string;
}

export function WelcomeCheckoutBillingAddressFromJSON(json: any): WelcomeCheckoutBillingAddress {
    return WelcomeCheckoutBillingAddressFromJSONTyped(json, false);
}

export function WelcomeCheckoutBillingAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): WelcomeCheckoutBillingAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zipCode': json['zip_code'],
        'addressLine1': json['address_line_1'],
        'addressLine2': !exists(json, 'address_line_2') ? undefined : json['address_line_2'],
    };
}

export function WelcomeCheckoutBillingAddressToJSON(value?: WelcomeCheckoutBillingAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zip_code': value.zipCode,
        'address_line_1': value.addressLine1,
        'address_line_2': value.addressLine2,
    };
}


