import styled from 'styled-components';
import Background from '../../../assets/new-ui/pricing/background-min.png';
import { colors } from '../colors';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-image: url(${Background});
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  & h1 {
    br {
      display: none;
    }
    color: ${colors.darkblue};
    @media screen and (max-width: 480px) {
      br {
        display: block;
      }
    }
  }
  @media screen and (max-width: 999px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    padding-bottom: 4rem;
  }
`;

export const VerticalCards = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardItem = styled.div`
  display: flex;
  margin-top: 5rem;
  @media screen and (max-width: 999px) {
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
  }
`;

export const Arrow1Wrapper = styled.div`
  position: absolute;
  bottom: -70%;
  right: -35%;
  img {
    width: 70px !important;
  }
  @media screen and (max-width: 999px) {
    bottom: -200%;
    right: -45%;
  }
  @media screen and (max-width: 480px) {
    bottom: -190%;
    right: -25%;
    img {
      width: 50px !important;
    }
  }
`;
export const Arrow2Wrapper = styled.div`
  position: absolute;
  bottom: -70%;
  left: -50%;
  img {
    width: 70px !important;
  }
  @media screen and (max-width: 999px) {
    bottom: -210%;
    right: -45%;
  }
  @media screen and (max-width: 480px) {
    bottom: -220%;
    left: -30%;
    img {
      width: 50px !important;
    }
  }
`;
export const Arrow3Wrapper = styled.div`
  position: absolute;
  bottom: -50%;
  right: -35%;
  img {
    width: 70px !important;
  }
  @media screen and (max-width: 999px) {
    bottom: -160%;
    right: -45%;
  }
  @media screen and (max-width: 480px) {
    bottom: -190%;
    right: -25%;
    img {
      width: 50px !important;
    }
  }
`;

export const CardImageWrapper = styled.div`
  display: flex;
  width: 220px;
  margin-right: 8rem;
  position: relative;
  img {
    width: 220px;
  }
  @media screen and (max-width: 999px) {
    margin-right: 0;
  }
`;
export const CardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h5 {
    color: ${colors.gray};
  }
  h1 {
    color: ${colors.darkblue};
    font-size: 28px;
    small {
      color: ${colors.darkblue};
      font-weight: 600;
    }
    br {
      display: block;
    }
  }
  p {
    color: ${colors.gray};
    @media screen and (max-width: 480px) {
      br {
        display: none;
      }
    }
  }
  @media screen and (max-width: 999px) {
    align-items: center;
    text-align: center;
  }
`;

export const CustomBr = styled.br`
  display: ${({ sm }) => (sm ? 'none !important' : null)};
  @media screen and (max-width: 480px) {
    display: ${({ sm }) => (sm ? 'block !important' : null)};
  }
`;
