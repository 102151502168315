/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestCheck
 */
export interface RequestCheck {
    /**
     * 
     * @type {string}
     * @memberof RequestCheck
     */
    pickupDate: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCheck
     */
    pickupStart: string;
}

export function RequestCheckFromJSON(json: any): RequestCheck {
    return RequestCheckFromJSONTyped(json, false);
}

export function RequestCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pickupDate': json['pickup_date'],
        'pickupStart': json['pickup_start'],
    };
}

export function RequestCheckToJSON(value?: RequestCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pickup_date': value.pickupDate,
        'pickup_start': value.pickupStart,
    };
}


