import React from 'react';

import { Header } from 'components/Header';
import { useAuth } from 'hooks';
import { Helmet } from 'react-helmet';

import IntroWrapper from './IntroWrapper/IntroWrapper';
import HowItWorks from './HowItWorks/HowItWorks';

import InputArea from './InputArea/InputArea';

const LazyPickTheBest = React.lazy(() => import('./PickTheBest/PickTheBest'));
const LazyBenefits = React.lazy(() => import('./Benefits/Benefits'));
const LazyPriceList = React.lazy(() => import('./PriceList/PriceList'));
const LazyHaveMoreQuestion = React.lazy(() => import('./HaveMoreQuestions/HaveMoreQuestions'));

const Pricing = () => {
  const { user, logout } = useAuth();

  return (
    <>
      <Header logout={logout} user={user} />
      <Helmet>
        <title>WashMix — Pricing</title>
        <meta name="description" content="Pricing" />
      </Helmet>
      <IntroWrapper />
      <HowItWorks />
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyPickTheBest />
      </React.Suspense>
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyBenefits />
      </React.Suspense>
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyPriceList />
      </React.Suspense>
      <InputArea />
      <React.Suspense fallback={<div>Loading...</div>}>
        <LazyHaveMoreQuestion />
      </React.Suspense>
    </>
  );
};

export default Pricing;
