export const colors = {
  lightblue: '#3cb8ef',
  darkblue: '#012f57',
  skyblue: '#dbf0fc',
  gray: '#868586',
  darkgray: '#706e6e',
  lightgray: '#86858678',
  linegray: '#818182',
  blue: '#1e74a3',
  deepblue: '#003459',
  sky: '#f0f5f8',
  green: '#2ec35f',
};
