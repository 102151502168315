import styled from 'styled-components';
import { colors } from './colors';

export const NavbarWrapper = styled.div`
  header {
    background-color: ${({ bg }) => `${bg} !important`};
    nav {
      ul {
        li {
          a {
            color: ${({ linksColor }) => `${linksColor} !important`};
            @media screen and (max-width: 1023px) {
              color: #003459 !important;
            }
          }
        }
      }
    }
  }
`;
export const MainContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const BgRemover = styled.div`
  background-color: ${({ bg }) => (bg ? bg : colors.skyblue)};
  & div {
    background-image: none !important;
  }
`;

export const RedesignInputArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  div:nth-of-type(1) {
    background: ${colors.skyblue};
    max-width: 1000px;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
    div:nth-of-type(1) {
      p {
        color: black;
      }
      form {
        div {
          margin-top: 0;
          div {
            input {
              color: black;
              border-bottom: 2px solid black;
              ::placeholder {
                color: black;
              }
            }
          }
        }
      }
    }
  }
`;
