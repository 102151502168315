/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordResetConfirm
 */
export interface PasswordResetConfirm {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    newPassword: string;
}

export function PasswordResetConfirmFromJSON(json: any): PasswordResetConfirm {
    return PasswordResetConfirmFromJSONTyped(json, false);
}

export function PasswordResetConfirmFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetConfirm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'token': json['token'],
        'newPassword': json['new_password'],
    };
}

export function PasswordResetConfirmToJSON(value?: PasswordResetConfirm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'token': value.token,
        'new_password': value.newPassword,
    };
}


