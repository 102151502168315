import styled from 'styled-components';
import { colors } from '../colors';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: ${colors.sky};
  h1 {
    color: ${colors.darkblue};
    text-align: center;
    margin: 0;
  }
  @media screen and (max-width: 999px) {
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    padding-bottom: 3rem;
  }
`;

export const ImageWrapper = styled.section`
  position: absolute;
  top: -38px;
  left: 50%;
  transform: translateX(-50%) !important;
  img {
    width: 150px;
  }
`;
export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5rem;
  padding-right: 3rem;
  width: 100%;
  max-width: 1600px;
  @media screen and (max-width: 999px) {
    flex-direction: column;
    padding-left: 3rem;
    padding-right: 3rem;
  }
`;
export const BigImageWrapper = styled.div`
  display: flex;
  width: 55%;
  img {
    max-width: 100%;
    margin-left: auto;
  }
  @media screen and (max-width: 999px) {
    width: 100%;
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    img {
      width: 100%;
    }
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  h2 {
    color: ${colors.lightblue};
    font-weight: 600;
    font-size: 28px;
  }
  p {
    color: ${colors.gray};
    @media screen and (max-width: 999px) {
      br {
        display: none;
      }
    }
  }
  @media screen and (max-width: 999px) {
    width: 45%;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const CustomBr = styled.br`
  display: ${({ sm }) => (sm ? 'none !important' : null)};
  @media screen and (max-width: 480px) {
    display: ${({ sm }) => (sm ? 'block !important' : null)};
  }
`;
