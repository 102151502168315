export const FAQQuestions = [
  {
    id: 1,
    question: 'What are the differences between Synthetic Leather & Genuine (Real) Leather?',
    answer:
      'Syntheric Leather is usually made from polyester material or similar, and therefore may not require full leather care. This however, depends on the type and quality of fabric. 100% Genuine Leather however, must undergo through full leather care, conditioning and protection. Althrough both are sensitive, full leather care however is a more complex and time consuming process and is preserved for Genuine Leather products.',
  },
  {
    id: 2,
    question: 'How long does Leather and Suede Care and Conditioning take?',
    answer:
      'The time frame for a typical leather- or suede care depends on the quality of leather or suede as well as the size, color and type of fabric. Anywhere from the short of 2-5 business days to as much as 10 business days to complete work.',
  },
  {
    id: 3,
    question: 'I have a leather purse/bag; will the entire purse/bag get cleaned [in/out]?',
    answer:
      'Not always, this depends on the type of bag we have to work with. With most some of the inner lining will be steamed and/or cleaned - but only partially. This also depends on the design of the bag and how the manufacturer layers additional fabric to protect the Leather portion on the outside. In short, Genuine Leather can NOT be in full contact with water or water-based-solution as it may damage the leather. We therefore, put most of the focus on the Leather portion, where the majority of the focus should be.',
  },
  {
    id: 4,
    question: 'How often I should get my leather garments and bags cleaned and conditioned?',
    answer:
      'This depends on where you live [humid or dry environment] and how often you used your garment or bag. Good rule of thumb is to clean once every 6 months and if used frequently more treatment maybe best. Additionally, it also depends on the type of leather used to make the garment or bag. Thin vs. thick. With thin leathers to avoid breakage more frequent is advised - but in general every 6-12mo will be sufficient.',
  },
  {
    id: 5,
    question: 'How do I send in my order [the first time]?',
    answer:
      "First timers simply use any bag you have, items can be mixed into one bag (No need to separate laundry from dry clean - Laundry does NOT include WASH & FOLD - if you're sending in WASH & FOLD it MUST go into a separate bag). Be sure to have your information on a piece of paper and placed inside your bag and leave outside. When we receive and process your order, we’ll send your own unique laundry bag and Doorhook as needed [and Welcome Box for WashMix Advantage™ members]. Note: Be sure to include any necessary information when signing up for service. I.e. GATE Code, Company Name, building # etc.",
  },
  {
    id: 6,
    question: 'I live in a secure building, how do I give access to the WashMix team to access my unit?',
    answer: `As you complete your online account, you will see a placement to leave additional note for deliveries. Here you can include any Gate Code, Butterfly Access code, or information such as 'Use the CallBox to locate name - "Smith, John", Then Press ENTER, and I will buzz you in'. Its crucial to provide accurate information so that we can easily access your unit for easy drop off and pickups. In the event that there is no such services, other methods include - leaving your order with the Leasing Office, or Call your phone upon arrival. IF any questions for other unique method, we can help - email us at cs@washmix.com`,
  },
];
