export const PreservationQuestions = [
  {
    id: 1,
    question: 'How do I schedule my first pickup? Can I check pricing BEFORE scheduling a pickup?',
    answer: `Using any service through WashMix.com is a as simple as REQUESTING A PICKUP. You will also get a Text Confirmation with your pickup date. IF its your first time, your assigned account representative will also contact you prior to your pickup day. However, IF you need to check and confirm pricing or schedule, BE SURE to do this prior to registering and signing up. You can email us directly at CS@WashMix.com; include any questions you may have and if necessary a picture or a few pictures of the article of clothing or gown you are inquiring about. We are quick to respond to any inquiry; however expect a response within the first hour to as long as 48 hours.`,
  },
  {
    id: 2,
    question:
      'What if I have more than one gown to clean and/or Clean & Preserve, do I need to request two separate pickups?',
    answer:
      'NO! For example, just as you’d send in 10 shirts or multiple items to clean with one pickup request, you can also include multiple gowns for clean and/or Clean & Preservation. Please note, multiple gowns will be charged separately and IF clean & preservation is needed, then each gown will also be in a separate box when done and completed.',
  },
  {
    id: 3,
    question:
      'Typically, how long does it take to clean [only] a wedding Gown? How long does it take to clean & preserve a wedding Gown?',
    answer:
      'Typically, this depends on the complexity of the gown. But for most wedding gowns, 3-6 business days is the time frame for clean ONLY and roughly 3-6 weeks for Clean & Preservation. Here at WashMix, we use museum style preservation, which is a thorough practice in cleaning and preserving a gown, which when stored in a dry environment, can be safe for up to 30 years. To achieve this significant milestone, our dedicated team, will require the time frame of 3-6 weeks to complete the work.',
  },
  {
    id: 4,
    question: 'How do I send in my Wedding Gown order [the first time]?',
    answer:
      'First timers simply use any bag you have, items can be mixed into one bag. Be sure to have your information + the work needed [i.e. Clean ONLY OR Clean & Preservation] on a piece of paper and placed inside your bag and leave outside. When we receive and process your order, we’ll send your own unique laundry bag and Doorhook as needed [and Welcome Box for WashMix Advantage™ members]. Note: Be sure to include any necessary information when signing up for service. I.e. GATE Code, Company Name, building # etc.',
  },
  {
    id: 5,
    question: 'I live in a secure building, how do I give access to the WashMix team to access my unit?',
    answer: `As you complete your online account, you will see a placement to leave additional note for deliveries. Here you can include any Gate Code, Butterfly Access code, or information such as ‘Use the CallBox to locate name - “Smith, John”, Then Press ENTER, and I will buzz you in’. Its crucial to provide accurate information so that we can easily access your unit for easy drop off and pickups. In the event that there is no such services, other methods include - leaving your order with the Leasing Office, or Call your phone upon arrival. IF any questions for other unique method, we can help - email us at cs@washmix.com`,
  },
  {
    id: 6,
    question: 'What if I want to arrange for pickups to be from my hotel or the wedding venue, what should I do?',
    answer: `For such instances, its best to include the full address of the venue or the location where we’ll pickup the gown. Be sure to include as much detail as necessary under the “DELIVERY INSTRUCTIONS” [i.e. Name of the Hotel + Hotel Lobby OR Concierge OR Room#]. IF any question, we can help - email us at cs@washmix.com.`,
  },
];
