import React from 'react';
import Dropdown from './Dropdown';
import { Container } from './Dropdown.style';

export const AreaRugFAQ = () => {
  return (
    <Container>
      <h1>Rug FAQ</h1>
      <Dropdown />
    </Container>
  );
};
