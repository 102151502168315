import React from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import styles from './Orders.module.scss';
import { Forward } from 'icons';
import { Body, Body2, Title } from 'ui';
import { useApi } from 'hooks';
import { formatDateLong } from 'utils/date';
import { capitalize } from '../../../utils/string';
import { toast } from 'react-toastify';

type Params = {
  id?: string;
};

export const Order = () => {
  const { ClientGetOrderPdf } = useApi();
  const { id } = useParams<Params>();
  const { getOrders } = useApi();
  const { data: orders } = useQuery('orders', getOrders);
  const order = orders?.find((o) => o.id === Number(id));
  if (!order) {
    return null;
  }
  console.log(order);

  const fetchOrderPdf = async () => {
    // Display initial "Getting PDF" message with a 5-second duration
    const gettingPdfToast = toast.success('Getting PDF...', { autoClose: 5000 });

    try {
      if (order.id !== undefined) {
        const response = await ClientGetOrderPdf(order.id.toString());
        // Rest of the code...

        if (response?.raw?.status === 200) {
          const data = await response.value();
          console.warn(data, 'data');
          // Close the "Getting PDF" toast
          toast.dismiss(gettingPdfToast);
          toast.success(data?.message, { autoClose: 3000 });

          const pdfPath = data?.pdf_path;
          window.open(pdfPath);
        } else {
          toast.dismiss(gettingPdfToast);
          toast.error(response?.error, { autoClose: 3000 });
        }
      }
    } catch (error) {
      toast.dismiss(gettingPdfToast);
      toast.error('Error while getting PDF', { autoClose: 3000 });
    }
  };
  return (
    <div className={styles.order}>
      <div className={styles.order__back}>
        <Link to="/profile/orders" style={{ display: 'flex' }}>
          <Forward className={styles.order__icon} />
          <Body2>Back</Body2>
        </Link>
        <h6 onClick={fetchOrderPdf} style={{ cursor: 'pointer' }}>
          Download PDF
        </h6>
      </div>
      <div className={styles.list}>
        <div className={styles.list__date}>
          <Body2 color="white">{formatDateLong(order.created)}</Body2>
        </div>
        <div className={styles.list__items}>
          {order.basket?.itemList.map((item) => (
            <div key={item.id} className={styles.list__item}>
              <Body>{item.count}</Body>
              <Body className={styles.list__itemName}>{item.item}</Body>
              <Body>${item.dollarAmount}</Body>
            </div>
          ))}
          {order.basket?.extraItems.map((item) => (
            <div key={item.title} className={styles.list__item}>
              <Body>1</Body>
              <Body className={styles.list__itemName}>{item.title}</Body>
              <Body>${item.dollarAmount}</Body>
            </div>
          ))}
          {!order.basket && order.subscription && (
            <div className={styles.list__item}>
              <Body>1</Body>
              <Body className={styles.list__itemName}>Package: {capitalize(order.subscription)}</Body>
              <Body>${order.dollarAmountWithDiscount}</Body>
            </div>
          )}
          {order.request && (
            <>
              <div className={styles.list__item}>
                <Body>1</Body>
                <Body className={styles.list__itemName}>Delivery</Body>
                <Body>${order.request.dollarAmountWithDiscount}</Body>
              </div>
              {order.request.isRush && (
                <div className={styles.list__item}>
                  <Body>1</Body>
                  <Body className={styles.list__itemName}>Rush Delivery</Body>
                  <Body>${order.request.dollarRushAmount}</Body>
                </div>
              )}
            </>
          )}
        </div>
        {order.coupon ? (
          <div className={styles.list__total}>
            <Title>
              Coupon [{order.coupon}] {order.discountType === 'percentage' ? order.discountPercent + '%' : null}
            </Title>
            <Title>${order.couponDiscount ? Number((order.couponDiscount / 100).toFixed(2)) : null}</Title>
          </div>
        ) : null}
        <div className={styles.list__discounts}>
          <Title className={styles.discount__text}>Discounts</Title>
          <Title className={styles.discount__text}>
            $
            {order.dollarDiscount
              ? order.couponDiscount
                ? Number(order.dollarDiscount - order.couponDiscount / 100).toFixed(2)
                : order.dollarDiscount
              : 0}
          </Title>
        </div>
        <div className={styles.list__total}>
          <Title>Total</Title>
          <Title>${order.dollarAmountWithDiscount}</Title>
        </div>
        {order.note && order.note !== '' ? (
          <div className={styles.list__total}>
            <Title>Note</Title>
            <p style={{ marginLeft: '2%' }}>{order.note}</p>
          </div>
        ) : null}
        {order.purpose ? (
          <div className={styles.list__total}>
            <Title>Transaction type</Title>
            <p style={{ marginLeft: '2%' }}>
              {order.purpose.toString() === 'credit'
                ? order.balanceBeforePurchase !== undefined && order.balanceAfterPurchase !== undefined
                  ? order.balanceBeforePurchase > order.balanceAfterPurchase
                    ? 'Credit removed from user account.'
                    : 'Credit added to user account.'
                  : 'Balance information not available.'
                : order.purpose.toString() === 'subscription' && order.subscription?.toString().toLowerCase() === 'payc'
                ? 'Customer chose washmix pay as you clean service .'
                : order.purpose.toString() === 'subscription' && order.subscription?.toString().toLowerCase() === 'gold'
                ? 'Customer chose washmix advantage pre-paid gold package.'
                : order.purpose.toString() === 'subscription' &&
                  order.subscription?.toString().toLowerCase() === 'platinum'
                ? 'Customer chose washmix advantage pre-paid platinum package.'
                : order.purpose.toString() === 'order'
                ? 'Amount deducted from user account for order.'
                : order.purpose.toString() === 'one_time_payment'
                ? 'One-time payment completed.'
                : order.purpose.toString() === 'admin_charged_client'
                ? 'Admin charged the customer for remainder order.'
                : 'Purpose not specified.'}
            </p>
          </div>
        ) : null}
        {order.created && new Date(order.created) > new Date(2022, 0, 16) ? (
          <div className={styles.list_balances}>
            <div className={styles.list_internal}>
              <Title className={styles.balance_title}>Balance Before</Title>
              <Title className={styles.balance_title}>
                $
                {order.balanceBeforePurchase !== null && order.balanceBeforePurchase !== undefined
                  ? order.balanceBeforePurchase / 100
                  : null}
              </Title>
            </div>
            <br></br>
            <div className={styles.list_internal}>
              <Title className={styles.balance_title}>Balance After</Title>
              <Title className={styles.balance_title}>
                $
                {order.balanceAfterPurchase !== null && order.balanceAfterPurchase !== undefined
                  ? order.balanceAfterPurchase / 100
                  : null}
              </Title>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
