/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestResponse
 */
export interface RequestResponse {
    /**
     * 
     * @type {number}
     * @memberof RequestResponse
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestResponse
     */
    address: number;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly pickupDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly pickupStart?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly pickupEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dropoffDate?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dropoffStart?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dropoffEnd?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestResponse
     */
    isRush?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestResponse
     */
    readonly isFree?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestResponse
     */
    schedule?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly amount?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dollarAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly discount?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dollarDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly amountWithDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dollarAmountWithDiscount?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestResponse
     */
    rushAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dollarRushAmount?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestResponse
     */
    customAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestResponse
     */
    readonly dollarCustomAmount?: string;
}

export function RequestResponseFromJSON(json: any): RequestResponse {
    return RequestResponseFromJSONTyped(json, false);
}

export function RequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'address': json['address'],
        'pickupDate': !exists(json, 'pickup_date') ? undefined : json['pickup_date'],
        'pickupStart': !exists(json, 'pickup_start') ? undefined : json['pickup_start'],
        'pickupEnd': !exists(json, 'pickup_end') ? undefined : json['pickup_end'],
        'dropoffDate': !exists(json, 'dropoff_date') ? undefined : json['dropoff_date'],
        'dropoffStart': !exists(json, 'dropoff_start') ? undefined : json['dropoff_start'],
        'dropoffEnd': !exists(json, 'dropoff_end') ? undefined : json['dropoff_end'],
        'isRush': !exists(json, 'is_rush') ? undefined : json['is_rush'],
        'isFree': !exists(json, 'is_free') ? undefined : json['is_free'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'schedule': !exists(json, 'schedule') ? undefined : json['schedule'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'dollarAmount': !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'dollarDiscount': !exists(json, 'dollar_discount') ? undefined : json['dollar_discount'],
        'amountWithDiscount': !exists(json, 'amount_with_discount') ? undefined : json['amount_with_discount'],
        'dollarAmountWithDiscount': !exists(json, 'dollar_amount_with_discount') ? undefined : json['dollar_amount_with_discount'],
        'rushAmount': !exists(json, 'rush_amount') ? undefined : json['rush_amount'],
        'dollarRushAmount': !exists(json, 'dollar_rush_amount') ? undefined : json['dollar_rush_amount'],
        'customAmount': !exists(json, 'custom_amount') ? undefined : json['custom_amount'],
        'dollarCustomAmount': !exists(json, 'dollar_custom_amount') ? undefined : json['dollar_custom_amount'],
    };
}

export function RequestResponseToJSON(value?: RequestResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'is_rush': value.isRush,
        'comment': value.comment,
        'schedule': value.schedule,
        'rush_amount': value.rushAmount,
        'custom_amount': value.customAmount,
    };
}


