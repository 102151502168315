/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateIntentResponse
 */
export interface CreateIntentResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateIntentResponse
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof CreateIntentResponse
     */
    secret: string;
}

export function CreateIntentResponseFromJSON(json: any): CreateIntentResponse {
    return CreateIntentResponseFromJSONTyped(json, false);
}

export function CreateIntentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIntentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicKey': json['public_key'],
        'secret': json['secret'],
    };
}

export function CreateIntentResponseToJSON(value?: CreateIntentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'public_key': value.publicKey,
        'secret': value.secret,
    };
}


