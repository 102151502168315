import React from 'react';
import './HowItWorks.scss';
import Click from 'assets/new-ui/pricing/click.svg';
import Checked from 'assets/new-ui/pricing/checked.svg';
import Locked from 'assets/new-ui/pricing/locked.svg';
const HowItWorks = () => {
  return (
    <div className="block__wrapper">
      <div className="block__container">
        <h1>How WashMix Advantage Program&trade; works?</h1>
        <h3>Let me show you!</h3>
        <p>WashMix Prepaid Credits are your very own currency.</p>
        <div className="block__cards__column">
          <div className="block__card__item">
            <div className="block__card__img">
              <img src={Click} alt="click" loading="lazy" />
            </div>
            <div className="block__card__info">
              <h3>
                Give Yourself The <br /> Credit You Deserve
              </h3>
              <p>
                With <strong>WashMix Advantage Program&trade;</strong>, you simply purchase credits in advance and spend
                them whenever you need your order(s) processed. While getting discounts and a ton of perks.
              </p>
            </div>
          </div>
          <div className="block__card__item">
            <div className="block__card__img">
              <img src={Checked} alt="checked" loading="lazy" />
            </div>
            <div className="block__card__info">
              <h3>
                Hassle-free With <br /> No Expiration
              </h3>
              <p>
                Your available credits <strong>NEVER</strong> expire. In short, you are <strong>NOT</strong> required to
                spend the credit by a set time.
              </p>
            </div>
          </div>
          <div className="block__card__item">
            <div className="block__card__img">
              <img src={Locked} alt="locked" loading="lazy" />
            </div>
            <div className="block__card__info">
              <h3>
                No Monthly Subscription, <br /> No Annual Fee
              </h3>
              <p>
                There are <strong>NO</strong> monthly subscriptions and <strong>NO</strong> annual fees. Every penny you
                purchase goestoward the desired service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
