/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface POSExtraItem
 */
export interface POSExtraItem {
    /**
     * 
     * @type {string}
     * @memberof POSExtraItem
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof POSExtraItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof POSExtraItem
     */
    instructions?: string | null;
    /**
     * 
     * @type {number}
     * @memberof POSExtraItem
     */
    readonly dollarAmount?: number | null;
}

export function POSExtraItemFromJSON(json: any): POSExtraItem {
    return POSExtraItemFromJSONTyped(json, false);
}

export function POSExtraItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): POSExtraItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'amount': json['amount'],
        'instructions': !exists(json, 'instructions') ? undefined : json['instructions'],
        'dollarAmount': !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
    };
}

export function POSExtraItemToJSON(value?: POSExtraItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'amount': value.amount,
        'instructions': value.instructions,
    };
}


