/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionChoose
 */
export interface SubscriptionChoose {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChoose
     */
    _package: string;
}

export function SubscriptionChooseFromJSON(json: any): SubscriptionChoose {
    return SubscriptionChooseFromJSONTyped(json, false);
}

export function SubscriptionChooseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChoose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_package': json['package'],
    };
}

export function SubscriptionChooseToJSON(value?: SubscriptionChoose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'package': value._package,
    };
}


