import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Header } from 'components/Header';
import { useAuth } from 'hooks';

import Intro from './Intro/Intro';
import HowItWorks from './HowItWorks/HowItWorks';

import InputArea from 'components/PricingNew/InputArea/InputArea';
import IntroImage from '../../assets/new-ui/wash-and-fold/intro-min.png';

import { BgRemover, MainContainer, NavbarWrapper, RedesignInputArea } from './styles';
import { FAQQuestions } from './FAQ/FAQ.info';

const LazySteps = React.lazy(() => import('./Steps/Steps'));
const LazyPickTheBest = React.lazy(() => import('components/PricingNew/PickTheBest/PickTheBest'));
const LazyCalculator = React.lazy(() => import('./Calculator/Calculator'));
const LazyDiscountInfo = React.lazy(() => import('./DiscountInfo/DiscountInfo'));
const LazyPickUpInfo = React.lazy(() => import('./PickUpInfo/PickUpInfo'));
const LazySheduling = React.lazy(() => import('./Sheduling/Sheduling'));
const LazyFAQ = React.lazy(() => import('./FAQ/FAQ'));
const LazyHaveMoreQuestion = React.lazy(() => import('components/PricingNew/HaveMoreQuestions/HaveMoreQuestions'));

const LeatherCare = ({ flag }: React.PropsWithChildren<{ flag: boolean }>) => {
  const { user, logout } = useAuth();
  const [navbarColor, setNavbarColor] = useState('transparent');
  const [navbarLinksColor, setNavbarLinksColor] = useState('white');

  useEffect(() => {
    if (flag && navbarColor !== 'white') {
      setNavbarColor('white');
      setNavbarLinksColor('#003459');
    }
    if (!flag && navbarColor !== 'transparent') {
      setNavbarColor('transparent');
      setNavbarLinksColor('white');
    }
  }, [flag, navbarColor]);
  return (
    <>
      <Helmet>
        <title>WashMix — Leather Care</title>
        <meta name="description" content="Leather Care" />
      </Helmet>
      <NavbarWrapper bg={navbarColor} linksColor={navbarLinksColor}>
        <Header logout={logout} user={user} />
      </NavbarWrapper>
      <MainContainer>
        <Intro background={IntroImage}>
          <h3>We process all forms leathers...</h3>
          <h1>Jackets & Coats, Shoes, Hand Bags, Purses, Gloves and Suede</h1>
          <h2>Our process is green, efficient & reliable</h2>
          <p>
            All with door-to-door Pickup & Delivery <br /> [Home & Office]
          </p>
        </Intro>
        <HowItWorks />
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySteps />
        </React.Suspense>
        <BgRemover>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyPickTheBest />
          </React.Suspense>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyCalculator />
          </React.Suspense>
        </BgRemover>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyDiscountInfo />
        </React.Suspense>
        <InputArea />
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyPickUpInfo />
        </React.Suspense>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySheduling />
        </React.Suspense>
        {/* <FAQ questions={FAQQuestions} /> */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyFAQ questions={FAQQuestions} title="Leather FAQ" />
        </React.Suspense>
        <RedesignInputArea>
          <InputArea />
        </RedesignInputArea>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyHaveMoreQuestion />
        </React.Suspense>
      </MainContainer>
    </>
  );
};

export default LeatherCare;
