import React, { useCallback } from 'react';
import { ModalTitle, Button } from 'ui';
import { Link } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  addressLine1?: string;
  zipCode?: string;
};

export const PhoneModal = ({ closeModal, isOpen }: Props) => {
  const handleHide = useCallback(() => {
    closeModal();
  }, [closeModal]);
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  const buttonStyle = {
    flex: '0 0 30%',
  };

  return (
    <ModalTitle isOpen={isOpen} close={handleHide} title={''}>
      <p>
        It looks like you have an established account with this phone. Try to <a href="/login">login</a> OR if any
        issues present contact us at cs@washmix.com.
      </p>
      <div className="registration-socialmedia">
        <p className="registration-description">Click below to login to your existing account</p>
        <Link
          to="/login"
          component={() => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                marginTop: '25px',
              }}
            >
              <div style={buttonContainerStyle}>
                <Button style={buttonStyle} className="primary" variant="primary" size="sm" href="/login" isBlock>
                  Login
                </Button>
              </div>
            </div>
          )}
        />
      </div>
    </ModalTitle>
  );
};
