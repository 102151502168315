/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubscriptionChooseResponse
 */
export interface SubscriptionChooseResponse {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionChooseResponse
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly subscription?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly amount?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly dollarAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly discount?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly dollarDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly amountWithDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChooseResponse
     */
    readonly dollarAmountWithDiscount?: string;
}

export function SubscriptionChooseResponseFromJSON(json: any): SubscriptionChooseResponse {
    return SubscriptionChooseResponseFromJSONTyped(json, false);
}

export function SubscriptionChooseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChooseResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subscription': !exists(json, 'subscription') ? undefined : json['subscription'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'dollarAmount': !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'dollarDiscount': !exists(json, 'dollar_discount') ? undefined : json['dollar_discount'],
        'amountWithDiscount': !exists(json, 'amount_with_discount') ? undefined : json['amount_with_discount'],
        'dollarAmountWithDiscount': !exists(json, 'dollar_amount_with_discount') ? undefined : json['dollar_amount_with_discount'],
    };
}

export function SubscriptionChooseResponseToJSON(value?: SubscriptionChooseResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


