/* eslint-disable prettier/prettier */
import React, { Suspense } from 'react';
import { useAuth } from 'hooks';
import { Prew } from './HomeComponents/Prew';
import { AfterPrev } from './HomeComponents/AfterPrev';
import { ProccesHome } from './HomeComponents/ProccesHome';
// import { ProcessHomeSec } from './HomeComponents/ProcessHomeSec';
// import { ProccesHomeTh } from './HomeComponents/ProcessHomeTh';
// import { Process } from './HomeComponents/Process';
// import { ProccesFooter } from './HomeComponents/ProcessFooter';
// import { Img } from './HomeComponents/Img';
// import { FAQHome } from './HomeComponents/FAQHome';
// import { ContactsNew } from 'components/ContactsNew/ContactsNew';
// import { BadgeContainerNew } from 'components/BadgeContainer/BageContainerNew';
import { LightHeader } from "../Header/LightHeader";
import { Header } from "../Header";

const LazyProcessHomeSec = React.lazy(() => import('./HomeComponents/ProcessHomeSec').then(module => ({ default: module.ProcessHomeSec })))
const LazyProccesHomeTh = React.lazy(() => import('./HomeComponents/ProcessHomeTh').then(module => ({ default: module.ProccesHomeTh })))
const LazyProcess = React.lazy(() => import('./HomeComponents/Process').then(module => ({ default: module.Process })))
const LazyProccesFooter = React.lazy(() => import('./HomeComponents/ProcessFooter').then(module => ({ default: module.ProccesFooter })))
const LazyImg = React.lazy(() => import('./HomeComponents/Img').then(module => ({ default: module.Img })))
const LazyFAQHome = React.lazy(() => import('./HomeComponents/FAQHome').then(module => ({ default: module.FAQHome })))
const LazyContactsNew = React.lazy(() => import('components/ContactsNew/ContactsNew').then(module => ({ default: module.ContactsNew })))
const LazyBadgeContainerNew = React.lazy(() => import('components/BadgeContainer/BageContainerNew').then(module => ({ default: module.BadgeContainerNew })))

export const HomeNew = ({flag}: React.PropsWithChildren<{flag:boolean}>) => {
  const { user, logout } = useAuth();
    return (
    <>
        <div>
            { flag ? <Header logout={logout} user={user} /> : <LightHeader logout={logout} user={user} />}
            <Prew/>
            <AfterPrev/>
            <ProccesHome/>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyProcessHomeSec/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyProccesHomeTh/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyProcess/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyProccesFooter/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyImg/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyFAQHome/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyContactsNew/>
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
              <LazyBadgeContainerNew/>
            </Suspense>
            {/* <ProcessHomeSec/>
            <ProccesHomeTh/>
            <Process/>
            <ProccesFooter/>
            <Img/>
            <FAQHome />
            <ContactsNew />
            <BadgeContainerNew /> */}
        </div>
    </>
  );
};
