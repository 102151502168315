/* eslint-disable prettier/prettier */
import React from 'react';

export const AfterPrev = () => {
  return (
    <section className="after-prev">
        <div className='afterPrev'>
            <div className="container">
                <div className="bg-image">
                </div>
            </div>
        </div>
    </section>
  );
};
