import { CardsWrapper, Container } from 'pages/LeatherCare/HowItWorks/HowItWorks.styles';

import React from 'react';

import arrow from '../../../assets/new-ui/preservation/arrow_horizontal.svg';
import dresses from '../../../assets/new-ui/preservation/dresses.svg';
import Car from '../../../assets/new-ui/wash-and-fold/car.svg';
import Switch from '../../../assets/new-ui/wash-and-fold/switch.svg';
import HomeClothes from '../../../assets/new-ui/preservation/empty_home.svg';
import {
  ArrowHorizontalWrapper,
  ArrowVerticalWrapper,
  BlueContainer,
  ExtraWrapper,
  WhiteContainer,
  WhiteLeft,
  WhiteRight,
  WhiteWrapper,
  ListItem,
  CardItem,
} from './HIW.style';
import { CustomBr } from '../Steps/Steps.style';
export const HIW = () => {
  return (
    <Container>
      <WhiteContainer>
        <WhiteWrapper>
          <ArrowHorizontalWrapper>
            <img src={arrow} alt="line-arrow" loading="lazy" />
          </ArrowHorizontalWrapper>
          <WhiteLeft>
            <div>
              <h1>Preserving Life&apos;s</h1>
              <h3>
                Most Fleeting <br />
                Moments
              </h3>
              <p>
                We have earned an outstanding reputation <br />
                for bridal apparel care and cleaning in the <br />
                Bay Area.
              </p>
              <p>
                Because of this prestigious reputation, we <br />
                are called upon to provide specialized <br />
                <strong>Wedding gown preservation</strong> and <br />
                <strong>restoration services</strong> to brides and bridal <br />
                salons throughout the Bay Area.
              </p>
            </div>
          </WhiteLeft>
          <WhiteRight>
            <img src={dresses} alt="variant" loading="lazy" />
          </WhiteRight>
          <ArrowVerticalWrapper>
            <img src={arrow} alt="line-arrow" loading="lazy" />
          </ArrowVerticalWrapper>
        </WhiteWrapper>
      </WhiteContainer>
      <BlueContainer>
        <h1>How It Works</h1>
        <CardsWrapper>
          <CardItem>
            <img src={Car} alt="car" loading="lazy" />
            <h1>PICK UP</h1>
            <ListItem>
              Signup and <strong>REQUEST A PICKUP</strong>
            </ListItem>
            <ListItem>
              Gather up your <strong>Gown + any</strong> <br /> <strong>Accessories </strong>
              and place inside a <br /> durable bag
            </ListItem>
            <ListItem>Leave your bag out for pickup</ListItem>
            <ListItem>
              [or <strong>shcedule</strong> a time frame for <br />
              us to pickup]
            </ListItem>
          </CardItem>
          <CardItem>
            <img src={Switch} alt="switch" loading="lazy" />
            <h1>PROCESSING</h1>
            <ListItem>
              Your order will be processed by <br />
              us in
              <strong> our processing center</strong>
            </ListItem>
            <ListItem>
              Rough estimate for Gown <br />
              Clean & Preservation is <br />
              4-6 Weeks
            </ListItem>
          </CardItem>
          <CardItem>
            <img src={HomeClothes} alt="home" loading="lazy" />
            <h1>DELIVERY</h1>
            <p>
              When your order is complete and <br />
              delivered - You&apos;ll get an{' '}
              <strong>
                automated <br /> confirmation
              </strong>
              via <strong>TEXT</strong> to your cellphone.
            </p>
            <ListItem>
              <span>
                With Gown Cleaning & <br />
                Preservation
              </span>
              we will notify you <br />
              <strong>via TEXT</strong> when the order has <br />
              been completed - & again on the <br />
              day of your delivery.
            </ListItem>
          </CardItem>
        </CardsWrapper>
        <ExtraWrapper>
          <p>
            We can arrange for a FREE <CustomBr sm /> Pick-up and Delivery
          </p>
          <p>
            Anywhere throughout the <CustomBr sm /> Bay Area, even from <br /> your Hotel or your Wedding Venue.
          </p>
        </ExtraWrapper>
      </BlueContainer>
    </Container>
  );
};
