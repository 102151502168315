/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Basket,
  BasketFromJSON,
  BasketFromJSONTyped,
  BasketToJSON,
  RequestResponse,
  RequestResponseFromJSON,
  RequestResponseFromJSONTyped,
  RequestResponseToJSON,
} from './';

/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  purpose: boolean;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  readonly id?: number;
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  readonly created?: Date;
  /**
   *
   * @type {Basket}
   * @memberof Order
   */
  basket: Basket | null;
  /**
   *
   * @type {RequestResponse}
   * @memberof Order
   */
  request: RequestResponse | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly subscription?: string | null;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  status?: OrderStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly prettyStatus?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  note?: string;
  /**
   *
   * @type {boolean}
   * @memberof Order
   */
  isSaveCard?: boolean;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly creditBack?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly dollarCreditBack?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly amount?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly dollarAmount?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly discount?: string;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  readonly dollarDiscount?: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly amountWithDiscount?: string;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly dollarAmountWithDiscount?: string;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  readonly discountPercent?: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  readonly discountType?: string;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  readonly couponDiscount?: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */

  readonly balanceBeforePurchase?: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */

  readonly balanceAfterPurchase?: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  coupon: string | null;
}

export function OrderFromJSON(json: any): Order {
  return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    created: !exists(json, 'created') ? undefined : new Date(json['created']),
    basket: BasketFromJSON(json['basket']),
    request: RequestResponseFromJSON(json['request']),
    subscription: !exists(json, 'subscription') ? undefined : json['subscription'],
    status: !exists(json, 'status') ? undefined : json['status'],
    prettyStatus: !exists(json, 'pretty_status') ? undefined : json['pretty_status'],
    note: !exists(json, 'note') ? undefined : json['note'],
    purpose: !exists(json, 'purpose') ? undefined : json['purpose'],
    isSaveCard: !exists(json, 'is_save_card') ? undefined : json['is_save_card'],
    creditBack: !exists(json, 'credit_back') ? undefined : json['credit_back'],
    dollarCreditBack: !exists(json, 'dollar_credit_back') ? undefined : json['dollar_credit_back'],
    amount: !exists(json, 'amount') ? undefined : json['amount'],
    dollarAmount: !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
    discount: !exists(json, 'discount') ? undefined : json['discount'],
    discountPercent: !exists(json, 'discount_percent') ? undefined : json['discount_percent'],
    discountType: !exists(json, 'coupon_discount_type') ? undefined : json['coupon_discount_type'],
    couponDiscount: !exists(json, 'coupon_discount') ? undefined : json['coupon_discount'],
    dollarDiscount: !exists(json, 'dollar_discount') ? undefined : json['dollar_discount'],
    balanceBeforePurchase: !exists(json, 'balance_before_purchase') ? undefined : json['balance_before_purchase'],
    balanceAfterPurchase: !exists(json, 'balance_after_purchase') ? undefined : json['balance_after_purchase'],
    amountWithDiscount: !exists(json, 'amount_with_discount') ? undefined : json['amount_with_discount'],
    dollarAmountWithDiscount: !exists(json, 'dollar_amount_with_discount')
      ? undefined
      : json['dollar_amount_with_discount'],
    coupon: json['coupon'],
  };
}

export function OrderToJSON(value?: Order | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    basket: BasketToJSON(value.basket),
    request: RequestResponseToJSON(value.request),
    status: value.status,
    note: value.note,
    is_save_card: value.isSaveCard,
    coupon: value.coupon,
    purpose: value.purpose,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum OrderStatusEnum {
  Accepted = 'accepted',
  InProgress = 'in_progress',
  Completed = 'completed',
}
