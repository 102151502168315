import React, { useState } from 'react';
import { AreaRugQuestions } from './AreaRugQuestions';
// import { FAQQuestions } from './FAQ.info';
import {
  Button,
  DropdownContainer,
  ItemAnswerWrapper,
  ItemButtonWrapper,
  ItemQuestion,
  ItemQuestionWrapper,
  ItemWrapper,
} from './Dropdown.style';

// const URL_REGEX = /CS@WashMix.com|cs@washmix.com/;
// const SITE_REGEX = /WashMix.com/;
// const renderText = (txt) => {
//   return txt.split(' ').map((part) => {
//     if (URL_REGEX.test(part)) {
//       return <a href="mailto:cs@washmix.com">{part} </a>;
//     } else if (SITE_REGEX.test(part)) {
//       return <a href="http://WashMix.com">{part} </a>;
//     } else {
//       return part + ' ';
//     }
//   });
// };

const Dropdown = ({ questions }) => {
  const [selectedQuestion, setSelectedQuestion] = useState();

  const toggleDropdown = (id) => {
    if (id !== selectedQuestion) setSelectedQuestion(id);
    else setSelectedQuestion(99);
  };
  return (
    <DropdownContainer>
      {/* {questions.map((item) => (
        <ItemWrapper key={item.id}>
          <ItemQuestionWrapper onClick={() => toggleDropdown(item.id)}>
            <ItemQuestion>{item.question}</ItemQuestion>
            <ItemButtonWrapper>
              <Button>{selectedQuestion === item.id ? '-' : '+'}</Button>
            </ItemButtonWrapper>
          </ItemQuestionWrapper>
          {selectedQuestion === item.id && (
            <ItemAnswerWrapper>
              <p>{renderText(item.answer)}</p>
            </ItemAnswerWrapper>
          )}
        </ItemWrapper>
      ))} */}
      {/* question 1 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[0].id)}>
          <ItemQuestion>{AreaRugQuestions[0].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[0].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[0].id && (
          <ItemAnswerWrapper>
            <p>
              Rugs that experience normal wear, you should clean it every 1 to 3 years. For high traffic rugs or rugs in
              homes with pets, clean it yearly more frequent service is best. In general, rugs in entranceways,
              hallways, kithcens, and bathrooms should be professionally cleaned every 6 month to 1 year. Rugs in living
              rooms, family rooms, and dining rooms can go between cleanings for 1 to 3 years. For bedrooms, most rugs
              only need a through cleaning every 2-4 years, and for wall hanging rugs, every 3-5 years.
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 2 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[1].id)}>
          <ItemQuestion>{AreaRugQuestions[0].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[1].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[1].id && (
          <ItemAnswerWrapper>
            <p>
              This depends on the intensity of the stain or odor. We do not guarantee removal of such cases as it
              depends on the type of stain, whether its been treated or not, the duration and time the stain or odor has
              been on the rug; the type and quality of the rug. Due to many factors named here, determining whether a
              stain or odor can be removed is difficult - however a good rule of thumb is to <br /> <br />
              i. Avoid treating it with solutions - unless you’re certain you can remove it. If you do in fact treat the
              area and the stain/odor is still intact, this will make it more difficult for us to remove <br /> <br />
              ii. Act ASAP. Its a good idea to wipe off the area but to also schedule a pickup through WashMix and have
              us do the rest as soon as possible
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 3 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[2].id)}>
          <ItemQuestion>{AreaRugQuestions[2].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[2].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[2].id && (
          <ItemAnswerWrapper>
            <p>
              This depends on the usage. IF the rug is frequently used or walked over [with shoes etc] or in an area
              where its exposed to direct sunlight throughout the year - we recommend cleaning 2X a year or every 6
              months [or at times more]. Otherwise, for most rugs 1-2 times a year is sufficient.
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 4 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[3].id)}>
          <ItemQuestion>{AreaRugQuestions[3].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[3].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[3].id && (
          <ItemAnswerWrapper>
            <p>
              a. The best practice is to have WASHMIX team to inspect AND roll the rug. We can also see if there are any
              damages or discoloration prior to taking the rug. There will be an additional charge for this [$29-$99/rug
              and depending on the size of the rug]. However, if you decide to do this yourself, you can, and have the
              rug ready outside for our team to pickup the rug on the designated pickup day. When you schedule your
              pickup, be sure to have your rug out and ready for pickup on the day of OR if we are inspecting the rug be
              sure to be present for this service. IF any questions, you can email us at{' '}
              <a href="mailto:cs@washmix.com">cs@washmix.com</a> prior to pickup or scheduling. <br /> <br />
              b. Scheduling a pickup for rug is no different than for typical dry cleaning [for established clients with
              WASHMIX account - simply text the phrase ‘pickup’ to <a href="tel:415-993-9274">415-993-9274</a>. Be sure
              to text FROM the same cell phone # you used to establish your account]. For NON-WashMix account holders,
              be sure to signup first by clicking on REQUEST PICKUP on any page to start your account & schedule a
              pickup.
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 5 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[4].id)}>
          <ItemQuestion>{AreaRugQuestions[4].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[4].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[4].id && (
          <ItemAnswerWrapper>
            <p>
              For most rugs and this also depends on the size and if there are any stains or odors to be worked on -
              rough estimate for most rugs is 7-12 business days. IF you have any questions or would like to RUSH the
              order, 1st schedule the pickup online and select rush; and 2nd contact us regarding the rug and rush
              service [<a href="mailto:cs@washmix.com">cs@washmix.com</a>]
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 6 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[5].id)}>
          <ItemQuestion>{AreaRugQuestions[5].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[5].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[5].id && (
          <ItemAnswerWrapper>
            <p>
              Here at WashMix we do ALL types of rugs. There are some exceptions with Cowhide or sheep skins that makes
              the process limited. For such rugs please contact us prior to scheduling a pickup{' '}
              <a href="mailto:cs@washmix.com">cs@washmix.com</a>
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 7 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[6].id)}>
          <ItemQuestion>{AreaRugQuestions[6].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[6].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[6].id && (
          <ItemAnswerWrapper>
            <p>
              YES! As always we recommend all clients to select WashMix Advantage Program (tm). It will qualify you for
              ongoing discounts and your pre-purchased credit never expires.
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
      {/* Question 8 */}
      <ItemWrapper>
        <ItemQuestionWrapper onClick={() => toggleDropdown(AreaRugQuestions[7].id)}>
          <ItemQuestion>{AreaRugQuestions[7].question}</ItemQuestion>
          <ItemButtonWrapper>
            <Button>{selectedQuestion === AreaRugQuestions[7].id ? '-' : '+'}</Button>
          </ItemButtonWrapper>
        </ItemQuestionWrapper>
        {selectedQuestion === AreaRugQuestions[7].id && (
          <ItemAnswerWrapper>
            <p>
              a. YES! You can schedule a pickup directly through your online portal - this will also allow you to select
              any specific dates in the future. OR [for established clients with WASHMIX account - simply text the
              phrase ‘pickup’ to <a href="tel:415-993-9274">415-993-9274</a>. Be sure to text FROM the same cell phone #
              you used to establish your account]. For NON-WashMix account holders, be sure to signup first by clicking
              on REQUEST PICKUP on any page to start your account & schedule a pickup. <br /> <br />
              b. IF you still want a more specific time frame or date - you can contact us prior to scheduling a pickup
              <a href="mailto:cs@washmix.com"> cs@washmix.com</a> and we’ll assist you.
            </p>
          </ItemAnswerWrapper>
        )}
      </ItemWrapper>
    </DropdownContainer>
  );
};

export default Dropdown;
