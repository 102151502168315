/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Price
 */
export interface Price {
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    readonly dollarAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    readonly title?: string;
    /**
     * 
     * @type {number}
     * @memberof Price
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    unit?: PriceUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    readonly prettyUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof Price
     */
    readonly image?: string;
}

export function PriceFromJSON(json: any): Price {
    return PriceFromJSONTyped(json, false);
}

export function PriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Price {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'dollarAmount': !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'prettyUnit': !exists(json, 'pretty_unit') ? undefined : json['pretty_unit'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function PriceToJSON(value?: Price | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'count': value.count,
        'unit': value.unit,
    };
}

/**
* @export
* @enum {string}
*/
export enum PriceUnitEnum {
    Pcs = 'pcs',
    Set = 'set',
    Lbs = 'lbs',
    SqFt = 'sq_ft',
    Bag = 'bag',
    Pleat = 'pleat'
}


