/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {WelcomeCheckoutBillingAddress} from "./WelcomeCheckoutBillingAddress";
/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly email?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly subscription?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    readonly balance?: number;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    readonly dollarBalance?: number;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    stripeId?: string | null;
    /**
     * 
     * @type {object}
     * @memberof Profile
     */
    billingAddress?: BillingAddress;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    starch?: ProfileStarchEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    noCrease?: ProfileNoCreaseEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    fixTears?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    detergents?: ProfileDetergentsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    fabricSoftener?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Profile
     */
    isAutoBilling?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    mainCard?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    mainPhone?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    mainAddress?: number | null;
}
export interface BillingAddress {
    /**
     *
     * @type {string}
     * @memberof BillingAddress
     */
    zipCode: string;
    /**
     *
     * @type {string}
     * @memberof BillingAddress
     */
    addressLine1: string;
    /**
     *
     * @type {string}
     * @memberof BillingAddress
     */
    addressLine2?: string;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'subscription': !exists(json, 'subscription') ? undefined : json['subscription'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'dollarBalance': !exists(json, 'dollar_balance') ? undefined : json['dollar_balance'],
        'stripeId': !exists(json, 'stripe_id') ? undefined : json['stripe_id'],
        'billingAddress': !exists(json, 'billing_address') ? undefined : BillingAddressFromJSON(json['billing_address']),
        'starch': !exists(json, 'starch') ? undefined : json['starch'],
        'noCrease': !exists(json, 'no_crease') ? undefined : json['no_crease'],
        'fixTears': !exists(json, 'fix_tears') ? undefined : json['fix_tears'],
        'detergents': !exists(json, 'detergents') ? undefined : json['detergents'],
        'fabricSoftener': !exists(json, 'fabric_softener') ? undefined : json['fabric_softener'],
        'isAutoBilling': !exists(json, 'is_auto_billing') ? undefined : json['is_auto_billing'],
        'mainCard': !exists(json, 'main_card') ? undefined : json['main_card'],
        'mainPhone': !exists(json, 'main_phone') ? undefined : json['main_phone'],
        'mainAddress': !exists(json, 'main_address') ? undefined : json['main_address'],
    };
}

export function BillingAddressFromJSON(json: any): BillingAddress {
    return BillingAddressFromJSONTyped(json, false);
}

export function BillingAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'zipCode': json['zip_code'],
        'addressLine1': json['address_line_1'],
        'addressLine2': !exists(json, 'address_line_2') ? undefined : json['address_line_2'],
    };
}

export function BillingAddressToJSON(value?: BillingAddress | null ): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'zip_code': value.zipCode,
        'address_line_1': value.addressLine1,
        'address_line_2': value.addressLine2,
    };
}


export function ProfileToJSON(value?: Profile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    const billingAddressJSON = BillingAddressToJSON(value.billingAddress)
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'stripe_id': value.stripeId,
        'billing_address': billingAddressJSON,
        'starch': value.starch,
        'no_crease': value.noCrease,
        'fix_tears': value.fixTears,
        'detergents': value.detergents,
        'fabric_softener': value.fabricSoftener,
        'is_auto_billing': value.isAutoBilling,
        'main_card': value.mainCard,
        'main_phone': value.mainPhone,
        'main_address': value.mainAddress,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProfileStarchEnum {
    None = 'none',
    Light = 'light',
    Medium = 'medium',
    Heavy = 'heavy'
}
/**
* @export
* @enum {string}
*/
export enum ProfileNoCreaseEnum {
    AllPants = 'all_pants',
    JeansOnly = 'jeans_only'
}
/**
* @export
* @enum {string}
*/
export enum ProfileDetergentsEnum {
    Scented = 'scented',
    HypoAllergenic = 'hypo_allergenic'
}


