import { colors } from 'pages/LeatherCare/colors';
import styled from 'styled-components';

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  @media screen and (max-width: 999px) {
    max-width: 60%;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 480px) {
    max-width: 100%;
    padding-left: 0;
  }
`;

export const WhiteLeft = styled.div`
  width: 38%;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 999px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 0;
    width: 75%;
    h1,
    h3,
    p {
      text-align: start;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  h1 {
    font-weight: 600;
    margin-bottom: 0;
    font-size: 28px;
    color: ${colors.darkblue};
    margin-bottom: 3rem;
    @media screen and (max-width: 999px) {
      text-align: center;
    }
  }
  h3 {
    color: ${colors.gray};
    font-weight: 600;
    @media screen and (max-width: 999px) {
      br {
        /* display: none; */
      }
    }
  }
  p {
    color: ${colors.gray};
    padding-left: 36px;
    position: relative;
    ::before {
      content: '-';
      position: absolute;
      left: 20px;
    }
    @media screen and (max-width: 480px) {
      max-width: 100%;
      /* text-align: center; */
      br {
        display: none;
      }
    }
  }
`;

export const ArrowHorizontalWrapper = styled.div`
  position: absolute;
  width: 300px;
  /* left: 300px; */
  top: 130px;
  @media screen and (max-width: 999px) {
    /* left: 12%; */
    right: 60%;
    width: 220px;
    top: 400px;
    rotate: 90deg;
    transform: scaleY(-1);
  }
  @media screen and (max-width: 977px) {
    top: 420px;
    right: 60%;
  }
  @media screen and (max-width: 830px) {
    /* left: 7%; */
    right: 60%;
  }
  @media screen and (max-width: 760px) {
    /* left: 2%; */
    right: 60%;
    /* top: 440px; */
  }
  @media screen and (max-width: 690px) {
    /* left: -5%; */
    right: 65%;
  }
  @media screen and (max-width: 620px) {
    /* left: -10%; */
    right: 60%;
    top: 480px;
  }
  @media screen and (max-width: 560px) {
    /* left: -18%; */
    right: 60%;
    top: 500px;
  }
  @media screen and (max-width: 500px) {
    /* left: -22%; */
    right: 60%;
    top: 520px;
  }
  @media screen and (max-width: 480px) {
    /* left: -25%; */
    right: 60%;
    width: 180px;
    top: 520px;
  }
`;

export const ArrowVerticalWrapper = styled.div`
  position: absolute;
  img {
    transform: rotate(90deg);
  }
  width: 300px;
  left: 74%;
  bottom: -35px;
  @media screen and (max-width: 999px) {
    width: 200px;
    left: 50%;
    bottom: -20px;
  }
  @media screen and (max-width: 480px) {
    width: 170px;
    left: 70%;
  }
`;

export const ExtraWrapper = styled.div`
  padding-right: 3rem;
  padding-left: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
  p:first-of-type {
    color: ${colors.green};
  }
  p {
    margin: 0;
    color: ${colors.gray};
    br {
      display: none;
      @media screen and (max-width: 999px) {
        display: block;
      }
    }
  }
  @media screen and (max-width: 999px) {
    padding-right: 2rem;
    padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 480px) {
    text-align: start;
    align-items: flex-start;
  }
`;

export const WhiteContainer = styled.div`
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9fbfe;
  @media screen and (max-width: 999px) {
    position: relative;
  }
`;
export const WhiteRight = styled.div`
  width: 50%;
  display: flex;
  img {
    width: 95%;
    /* width: 320px; */
    margin-left: auto;
  }
  @media screen and (max-width: 999px) {
    margin-top: 8rem;
    width: auto;
  }
  @media screen and (max-width: 999px) {
    img {
      width: 390px;
    }
  }
  @media screen and (max-width: 480px) {
    top: -130px;
    img {
      width: 270px;
    }
  }
`;

export const WhiteWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 1200px;
  padding-top: 10rem;
  padding-bottom: 10rem;
  position: relative;
  @media screen and (max-width: 999px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 999px) {
    padding-top: 2rem;
  }
`;

export const BlueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 6rem;
  /* background-color: #f0f5f8; */
  background-color: #fff;
  & h1 {
    color: ${colors.darkblue};
  }
  @media screen and (max-width: 999px) {
    padding-bottom: 1rem;
  }
  @media screen and (max-width: 480px) {
    padding-top: 4rem;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  :before {
    content: '-';
    position: absolute;
    margin-left: -10px;
  }
  color: ${({ color }) => (color ? color : colors.gray)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  @media screen and (max-width: 999px) {
  }
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  img {
    height: 70px;
  }
  h1 {
    margin-top: 2rem;
    text-align: center;
    font-size: 22px;
    color: ${colors.lightblue};
  }
  p {
    color: ${colors.gray};
  }
  @media screen and (max-width: 999px) {
    margin-bottom: 4rem;
  }
  @media screen and (max-width: 480px) {
    padding-left: 3rem;
    padding-right: 3rem;
    width: 100%;
  }
`;
