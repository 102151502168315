/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Signup
 */
export interface Signup {
    /**
     *
     * @type {string}
     * @memberof Signup
     */
    zipCode: string;
    /**
     *
     * @type {string}
     * @memberof Signup
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof Signup
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Signup
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof Signup
     */
    phone: string;
}

export function SignupFromJSON(json: any): Signup {
    return SignupFromJSONTyped(json, false);
}

export function SignupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Signup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'zipCode': json['zipCode'],
        'addressLine1': json['addressLine1'],
        'email': json['email'],
        'password': json['password'],
        'phone': json['phone'],
    };
}

export function SignupToJSON(value?: Signup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zipCode': value.zipCode,
        'addressLine1': value.addressLine1,
        'email': value.email,
        'password': value.password,
        'phone': value.phone,
    };
}
