import styled from 'styled-components';

export const Colorizer = styled.div`
  h1 {
    color: ${({ color }) => (color ? `${color} !important` : null)};
    font-size: 64px;
    margin: 0;
    padding: 0;
    line-height: 60px;
    @media screen and (max-width: 480px) {
      font-size: 36px;
      line-height: 36px;
      text-align: start;
    }
  }
  p {
    color: ${({ color }) => (color ? `${color} !important` : null)};
    @media screen and (max-width: 480px) {
      text-align: start;
    }
  }
`;

export const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    max-width: 469px;
  }
`;

export const PaddingRemover = styled.div`
  section:first-of-type {
    max-width: 1000px;
    @media screen and (max-width: 999px) {
      padding-bottom: 8rem;
    }
    @media screen and (max-width: 480px) {
      padding-bottom: 4rem;
    }
  }
`;

export const RugBr = styled.br`
  display: none;
  @media screen and (max-width: 999px) {
    display: block;
  }
`;

export const SchedulingWrapper = styled.div`
  section {
    top: -28px;
    img {
      width: 100px;
    }
  }
`;
