import React from 'react';
import { useAuth } from 'hooks';
import { Helmet } from 'react-helmet';

import IntroImage from '../../assets/new-ui/general-page/Background-min.png';
import { BgRemover, MainContainer, RedesignInputArea } from './styles';
import { Header } from 'components/Header';

import Intro from './Intro/Intro';
import HowItWorks from './HowItWorks/HowItWorks';
import InputArea from 'components/PricingNew/InputArea/InputArea';
import { GeneralFAQQuestions } from './FAQ/questions';
import { LightHeaderWithCustomStyles } from 'components/LightHeaderWithCustomStyles/LightHeaderWithCustomStyles';
import { colors } from 'components/PricingNew/colors';

const LazySteps = React.lazy(() => import('./Steps/Steps'));
const LazyPickTheBest = React.lazy(() => import('components/PricingNew/PickTheBest/PickTheBest'));
const LazyCalculator = React.lazy(() => import('./Calculator/Calculator'));
const LazyBenefits = React.lazy(() => import('components/PricingNew/Benefits/Benefits'));
const LazyPriceList = React.lazy(() => import('components/PricingNew/PriceList/PriceList'));
const LazyFAQ = React.lazy(() => import('pages/LeatherCare/FAQ/FAQ'));
const LazyHaveMoreQuestion = React.lazy(() => import('components/PricingNew/HaveMoreQuestions/HaveMoreQuestions'));

const GeneralPage = ({ flag, metaTitle }) => {
  const { user, logout } = useAuth();
  return (
    <>
      <Helmet>
        <title>WashMix — {metaTitle}</title>
        <meta name="description" content={`${metaTitle}`} />
      </Helmet>
      {flag ? (
        <Header logout={logout} user={user} />
      ) : (
        <LightHeaderWithCustomStyles
          logout={logout}
          user={user}
          logoFontColor={colors.darkblue}
          linksFontColor={colors.lightblue}
        />
      )}
      <MainContainer>
        <Intro background={IntroImage}>
          <h1>
            We believe in Green and Environmentally Friendly Practices and have kept it this way, since the start.
          </h1>
          <h2>Our process is green, efficient & reliable</h2>
          <p>
            All with door-to-door Pickup & Delivery <br /> [Home & Office]
          </p>
        </Intro>
        <HowItWorks />
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySteps />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <LazyPickTheBest />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <LazyBenefits />
        </React.Suspense>
        <BgRemover>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyCalculator />
          </React.Suspense>
        </BgRemover>
        <React.Suspense fallback={null}>
          <LazyPriceList />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <LazyFAQ questions={GeneralFAQQuestions} title="FAQ" />
        </React.Suspense>
        <RedesignInputArea>
          <InputArea />
        </RedesignInputArea>
        <React.Suspense fallback={null}>
          <LazyHaveMoreQuestion />
        </React.Suspense>
      </MainContainer>
    </>
  );
};

export default GeneralPage;
