/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressToJSON,
    Card,
    CardFromJSON,
    CardToJSON,
    CreateIntent,
    CreateIntentFromJSON,
    CreateIntentToJSON,
    CreateIntentResponse,
    CreateIntentResponseFromJSON,
    CreateIntentResponseToJSON,
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
    Invoice,
    InvoiceFromJSON,
    InvoiceToJSON,
    Location,
    LocationFromJSON,
    LocationToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    Order,
    OrderFromJSON,
    OrderToJSON,
    OrderApplyCoupon,
    OrderApplyCouponFromJSON,
    OrderApplyCouponToJSON,
    OrderApplyCouponResponse,
    OrderApplyCouponResponseFromJSON,
    OrderApplyCouponResponseToJSON,
    OrderRemoveCoupon,
    OrderRemoveCouponFromJSON,
    OrderRemoveCouponToJSON,
    Package,
    PackageFromJSON,
    PackageToJSON,
    PasswordResetConfirm,
    PasswordResetConfirmFromJSON,
    PasswordResetConfirmToJSON,
    Phone,
    PhoneFromJSON,
    PhoneToJSON,
    Profile,
    ProfileFromJSON,
    ProfileToJSON,
    Request,
    RequestFromJSON,
    RequestToJSON,
    RequestCheck,
    RequestCheckFromJSON,
    RequestCheckToJSON,
    Schedule,
    ScheduleFromJSON,
    ScheduleToJSON,
    SendEmailReset,
    SendEmailResetFromJSON,
    SendEmailResetToJSON,
    Service,
    ServiceFromJSON,
    ServiceToJSON,
    Signup,
    SignupFromJSON,
    SignupToJSON,
    SubscriptionCheckout,
    SubscriptionCheckoutFromJSON,
    SubscriptionCheckoutToJSON,
    SubscriptionChoose,
    SubscriptionChooseFromJSON,
    SubscriptionChooseToJSON,
    SubscriptionChooseResponse,
    SubscriptionChooseResponseFromJSON,
    SubscriptionChooseResponseToJSON,
    TokenObtainSliding,
    TokenObtainSlidingFromJSON,
    TokenObtainSlidingToJSON,
    TokenRefreshSliding,
    TokenRefreshSlidingFromJSON,
    TokenRefreshSlidingToJSON,
    TokenVerify,
    TokenVerifyFromJSON,
    TokenVerifyToJSON,
    TwilioFlexDeliveryWebhook,
    TwilioFlexDeliveryWebhookFromJSON,
    TwilioFlexDeliveryWebhookToJSON,
    TwilioFlexOnlineWorkersWebhook,
    TwilioFlexOnlineWorkersWebhookFromJSON,
    TwilioFlexOnlineWorkersWebhookToJSON,
    WelcomeCheckout,
    WelcomeCheckoutFromJSON,
    WelcomeCheckoutToJSON,
    WelcomeCheckoutResponse,
    WelcomeCheckoutResponseFromJSON,
    WelcomeCheckoutResponseToJSON,
    ZipCode,
    ZipCodeFromJSON,
    ZipCodeToJSON,
} from '../models';

export interface ClientAddressesCreateRequest {
    data: Address;
}

export interface ClientAddressesDeleteRequest {
    id: string;
}

export interface ClientAddressesPartialUpdateRequest {
    id: string;
    data: Address;
}

export interface ClientAddressesReadRequest {
    id: string;
}

export interface ClientAddressesUpdateRequest {
    id: string;
    data: Address;
}

export interface ClientAuthLoginCreateRequest {
    data: TokenObtainSliding;
}

export interface ClientAuthResetPasswordRequest {
    data: SendEmailReset;
}

export interface ClientAuthResetPasswordConfirmRequest {
    data: PasswordResetConfirm;
}

export interface ClientAuthSignupCreateRequest {
    data: Signup;
}

export interface ClientBillingCreateIntentCreateRequest {
    data: CreateIntent;
}

export interface ClientCardsCreateRequest {
    data: Card;
}

export interface ClientCardsDeleteRequest {
    id: string;
}

export interface ClientCardsPartialUpdateRequest {
    id: string;
    data: Card;
}

export interface ClientCardsReadRequest {
    id: string;
}

export interface ClientCardsUpdateRequest {
    id: string;
    data: Card;
}

export interface ClientCustomersCreateRequest {
    data: Customer;
}

export interface ClientJwtRefreshCreateRequest {
    data: TokenRefreshSliding;
}

export interface ClientJwtVerifyCreateRequest {
    data: TokenVerify;
}

export interface ClientOrdersApplyCouponCreateRequest {
    data: OrderApplyCoupon;
}

export interface ClientOrdersRemoveCouponCreateRequest {
    data: OrderRemoveCoupon;
}

export interface ClientPhonesCreateRequest {
    data: Phone;
}

export interface ClientPhonesDeleteRequest {
    id: string;
}

export interface ClientPhonesPartialUpdateRequest {
    id: string;
    data: Phone;
}

export interface ClientPhonesReadRequest {
    id: string;
}

export interface ClientPhonesUpdateRequest {
    id: string;
    data: Phone;
}

export interface ClientProfilePartialUpdateRequest {
    data: Profile;
}

export interface ClientProfileUpdateRequest {
    data: Profile;
}

export interface ClientRequestsCheckCreateRequest {
    data: RequestCheck;
}

export interface ClientRequestsCreateRequest {
    data: Request;
}

export interface ClientRequestsDeleteRequest {
    id: string;
}

export interface ClientRequestsListRequest {
    isUpcoming?: string;
}

export interface ClientRequestsPartialUpdateRequest {
    id: string;
    data: Request;
}

export interface ClientRequestsReadRequest {
    id: string;
}

export interface ClientRequestsUpdateRequest {
    id: string;
    data: Request;
}

export interface ClientSchedulesCreateRequest {
    data: Schedule;
}

export interface ClientSchedulesDeleteRequest {
    id: string;
}

export interface ClientSchedulesPartialUpdateRequest {
    id: string;
    data: Schedule;
}

export interface ClientSchedulesReadRequest {
    id: string;
}

export interface ClientSchedulesUpdateRequest {
    id: string;
    data: Schedule;
}

export interface ClientSmsDeliveryCreateRequest {
    data: TwilioFlexDeliveryWebhook;
}

export interface ClientSmsOnlineWorkersCreateRequest {
    data: TwilioFlexOnlineWorkersWebhook;
}

export interface ClientSubscriptionCheckoutCreateRequest {
    data: SubscriptionCheckout;
}

export interface ClientSubscriptionChooseCreateRequest {
    data: SubscriptionChoose;
}

export interface ClientWelcomeCheckoutCreateRequest {
    data: WelcomeCheckout;
}

export interface GetClientOrderPdf {
    order_id: string;
}
/**
 * 
 */
export class ClientApi extends runtime.BaseAPI {

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesCreateRaw(requestParameters: ClientAddressesCreateRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAddressesCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/addresses/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesCreate(data: Address): Promise<Address> {
        const response = await this.clientAddressesCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesDeleteRaw(requestParameters: ClientAddressesDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientAddressesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesDelete(id: string): Promise<void> {
        await this.clientAddressesDeleteRaw({ id: id });
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesListRaw(): Promise<runtime.ApiResponse<Array<Address>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/addresses/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressFromJSON));
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesList(): Promise<Array<Address>> {
        const response = await this.clientAddressesListRaw();
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesPartialUpdateRaw(requestParameters: ClientAddressesPartialUpdateRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientAddressesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAddressesPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesPartialUpdate(id: string, data: Address): Promise<Address> {
        const response = await this.clientAddressesPartialUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesReadRaw(requestParameters: ClientAddressesReadRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientAddressesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesRead(id: string): Promise<Address> {
        const response = await this.clientAddressesReadRaw({ id: id });
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesUpdateRaw(requestParameters: ClientAddressesUpdateRequest): Promise<runtime.ApiResponse<Address>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientAddressesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAddressesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/addresses/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddressToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity
     */
    async clientAddressesUpdate(id: string, data: Address): Promise<Address> {
        const response = await this.clientAddressesUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientAuthLoginCreateRaw(requestParameters: ClientAuthLoginCreateRequest): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAuthLoginCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/auth/login/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenObtainSlidingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientAuthLoginCreate(data: TokenObtainSliding): Promise<LoginResponse> {
        const response = await this.clientAuthLoginCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientAuthResetPasswordRaw(requestParameters: ClientAuthResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAuthResetPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/auth/forgot_password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailResetToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientAuthResetPassword(data: SendEmailReset): Promise<void> {
        await this.clientAuthResetPasswordRaw({ data: data });
    }

    /**
     */
    async clientAuthResetPasswordConfirmRaw(requestParameters: ClientAuthResetPasswordConfirmRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAuthResetPasswordConfirm.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/auth/reset_new_password/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetConfirmToJSON(requestParameters.data),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientAuthResetPasswordConfirm(data: PasswordResetConfirm): Promise<void> {
        await this.clientAuthResetPasswordConfirmRaw({ data: data });
    }

    /**
     */
    async clientAuthSignupCreateRaw(requestParameters: ClientAuthSignupCreateRequest): Promise<runtime.ApiResponse<Signup>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientAuthSignupCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/auth/signup/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignupFromJSON(jsonValue));
    }

    /**
     */
    async clientAuthSignupCreate(data: Signup): Promise<Signup> {
        const response = await this.clientAuthSignupCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Used in following cases:     - When client add a new card     - When client pass through a welcome scenario
     */
    async clientBillingCreateIntentCreateRaw(requestParameters: ClientBillingCreateIntentCreateRequest): Promise<runtime.ApiResponse<CreateIntentResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientBillingCreateIntentCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/billing/create_intent/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIntentToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateIntentResponseFromJSON(jsonValue));
    }

    /**
     * Used in following cases:     - When client add a new card     - When client pass through a welcome scenario
     */
    async clientBillingCreateIntentCreate(data: CreateIntent): Promise<CreateIntentResponse> {
        const response = await this.clientBillingCreateIntentCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * View that responds on Stripe\'s webhooks.
     */
    async clientBillingStripeWebhookCreateRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/client/billing/stripe_webhook/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * View that responds on Stripe\'s webhooks.
     */
    async clientBillingStripeWebhookCreate(): Promise<void> {
        await this.clientBillingStripeWebhookCreateRaw();
    }

    /**
     */
    async clientCardsCreateRaw(requestParameters: ClientCardsCreateRequest): Promise<runtime.ApiResponse<Card>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCardsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CardToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async clientCardsCreate(data: Card): Promise<Card> {
        const response = await this.clientCardsCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientCardsDeleteRaw(requestParameters: ClientCardsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCardsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientCardsDelete(id: string): Promise<void> {
        await this.clientCardsDeleteRaw({ id: id });
    }

    /**
     */
    async clientCardsListRaw(): Promise<runtime.ApiResponse<Array<Card>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CardFromJSON));
    }

    /**
     */
    async clientCardsList(): Promise<Array<Card>> {
        const response = await this.clientCardsListRaw();
        return await response.value();
    }

    /**
     */
    async clientCardsPartialUpdateRaw(requestParameters: ClientCardsPartialUpdateRequest): Promise<runtime.ApiResponse<Card>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCardsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCardsPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CardToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async clientCardsPartialUpdate(id: string, data: Card): Promise<Card> {
        const response = await this.clientCardsPartialUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientCardsReadRaw(requestParameters: ClientCardsReadRequest): Promise<runtime.ApiResponse<Card>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCardsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async clientCardsRead(id: string): Promise<Card> {
        const response = await this.clientCardsReadRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async clientCardsRefreshCreateRaw(): Promise<runtime.ApiResponse<Card>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async clientCardsRefreshCreate(): Promise<Card> {
        const response = await this.clientCardsRefreshCreateRaw();
        return await response.value();
    }

    /**
     */
    async clientCardsUpdateRaw(requestParameters: ClientCardsUpdateRequest): Promise<runtime.ApiResponse<Card>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientCardsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCardsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/cards/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CardToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CardFromJSON(jsonValue));
    }

    /**
     */
    async clientCardsUpdate(id: string, data: Card): Promise<Card> {
        const response = await this.clientCardsUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientCustomersCreateRaw(requestParameters: ClientCustomersCreateRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientCustomersCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/customers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     */
    async clientCustomersCreate(data: Customer): Promise<Customer> {
        const response = await this.clientCustomersCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientInvoicesListRaw(): Promise<runtime.ApiResponse<Array<Invoice>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/invoices/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InvoiceFromJSON));
    }

    /**
     */
    async clientInvoicesList(): Promise<Array<Invoice>> {
        const response = await this.clientInvoicesListRaw();
        return await response.value();
    }

    /**
     * Takes a sliding JSON web token and returns a new, refreshed version if the token\'s refresh period has not expired.
     */
    async clientJwtRefreshCreateRaw(requestParameters: ClientJwtRefreshCreateRequest): Promise<runtime.ApiResponse<TokenRefreshSliding>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientJwtRefreshCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/jwt/refresh/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenRefreshSlidingToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenRefreshSlidingFromJSON(jsonValue));
    }

    /**
     * Takes a sliding JSON web token and returns a new, refreshed version if the token\'s refresh period has not expired.
     */
    async clientJwtRefreshCreate(data: TokenRefreshSliding): Promise<TokenRefreshSliding> {
        const response = await this.clientJwtRefreshCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async clientJwtVerifyCreateRaw(requestParameters: ClientJwtVerifyCreateRequest): Promise<runtime.ApiResponse<TokenVerify>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientJwtVerifyCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/jwt/verify/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TokenVerifyToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenVerifyFromJSON(jsonValue));
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     */
    async clientJwtVerifyCreate(data: TokenVerify): Promise<TokenVerify> {
        const response = await this.clientJwtVerifyCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Retrieve a list of cities where we work and list of valid zip codes that we support.
     * PUBLIC METHOD.
     */
    async clientLocationsListRaw(): Promise<runtime.ApiResponse<Array<Location>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/client/locations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     * Retrieve a list of cities where we work and list of valid zip codes that we support.
     * PUBLIC METHOD.
     */
    async clientLocationsList(): Promise<Array<Location>> {
        const response = await this.clientLocationsListRaw();
        return await response.value();
    }

    /**
     */
    async clientOrdersApplyCouponCreateRaw(requestParameters: ClientOrdersApplyCouponCreateRequest): Promise<runtime.ApiResponse<OrderApplyCouponResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientOrdersApplyCouponCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/orders/apply_coupon/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderApplyCouponToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderApplyCouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientOrdersApplyCouponCreate(data: OrderApplyCoupon): Promise<OrderApplyCouponResponse> {
        const response = await this.clientOrdersApplyCouponCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientOrdersListRaw(): Promise<runtime.ApiResponse<Array<Order>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/orders/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderFromJSON));
    }

    /**
     */
    async clientOrdersList(): Promise<Array<Order>> {
        const response = await this.clientOrdersListRaw();
        return await response.value();
    }

    /**
     */
    async clientOrdersRemoveCouponCreateRaw(requestParameters: ClientOrdersRemoveCouponCreateRequest): Promise<runtime.ApiResponse<OrderApplyCouponResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientOrdersRemoveCouponCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/orders/remove_coupon/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrderRemoveCouponToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderApplyCouponResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientOrdersRemoveCouponCreate(data: OrderRemoveCoupon): Promise<OrderApplyCouponResponse> {
        const response = await this.clientOrdersRemoveCouponCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Shows a list of available packages. Mostly called from landing without authentication.
     * PUBLIC METHOD.
     */
    async clientPackagesListRaw(): Promise<runtime.ApiResponse<Array<Package>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/client/packages/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PackageFromJSON));
    }

    /**
     * Shows a list of available packages. Mostly called from landing without authentication.
     * PUBLIC METHOD.
     */
    async clientPackagesList(): Promise<Array<Package>> {
        const response = await this.clientPackagesListRaw();
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesCreateRaw(requestParameters: ClientPhonesCreateRequest): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientPhonesCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/phones/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesCreate(data: Phone): Promise<Phone> {
        const response = await this.clientPhonesCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesDeleteRaw(requestParameters: ClientPhonesDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPhonesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/phones/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesDelete(id: string): Promise<void> {
        await this.clientPhonesDeleteRaw({ id: id });
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesListRaw(): Promise<runtime.ApiResponse<Array<Phone>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/phones/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneFromJSON));
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesList(): Promise<Array<Phone>> {
        const response = await this.clientPhonesListRaw();
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesPartialUpdateRaw(requestParameters: ClientPhonesPartialUpdateRequest): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPhonesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientPhonesPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/phones/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesPartialUpdate(id: string, data: Phone): Promise<Phone> {
        const response = await this.clientPhonesPartialUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesReadRaw(requestParameters: ClientPhonesReadRequest): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPhonesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/phones/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesRead(id: string): Promise<Phone> {
        const response = await this.clientPhonesReadRaw({ id: id });
        return await response.value();
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesUpdateRaw(requestParameters: ClientPhonesUpdateRequest): Promise<runtime.ApiResponse<Phone>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientPhonesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientPhonesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/phones/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PhoneToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PhoneFromJSON(jsonValue));
    }

    /**
     * Methods to manipulate with `Address` entity.
     */
    async clientPhonesUpdate(id: string, data: Phone): Promise<Phone> {
        const response = await this.clientPhonesUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientProfilePartialUpdateRaw(requestParameters: ClientProfilePartialUpdateRequest): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientProfilePartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/profile/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     */
    async clientProfilePartialUpdate(data: Profile): Promise<Profile> {
        const response = await this.clientProfilePartialUpdateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientProfileReadRaw(): Promise<runtime.ApiResponse<Profile>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/profile/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     */
    async clientProfileRead(): Promise<Profile> {
        const response = await this.clientProfileReadRaw();
        return await response.value();
    }

    /**
     */
    async clientProfileUpdateRaw(requestParameters: ClientProfileUpdateRequest): Promise<runtime.ApiResponse<Profile>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientProfileUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/profile/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProfileToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfileFromJSON(jsonValue));
    }

    /**
     */
    async clientProfileUpdate(data: Profile): Promise<Profile> {
        const response = await this.clientProfileUpdateRaw({ data: data });
        return await response.value();
    }

    /**
     * Goal of this view - validate request pickup date and time.
     */
    async clientRequestsCheckCreateRaw(requestParameters: ClientRequestsCheckCreateRequest): Promise<runtime.ApiResponse<RequestCheck>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientRequestsCheckCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/check/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestCheckToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestCheckFromJSON(jsonValue));
    }

    /**
     * Goal of this view - validate request pickup date and time.
     */
    async clientRequestsCheckCreate(data: RequestCheck): Promise<RequestCheck> {
        const response = await this.clientRequestsCheckCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientRequestsCreateRaw(requestParameters: ClientRequestsCreateRequest): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientRequestsCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async clientRequestsCreate(data: Request): Promise<Request> {
        const response = await this.clientRequestsCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientRequestsDeleteRaw(requestParameters: ClientRequestsDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRequestsDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientRequestsDelete(id: string): Promise<void> {
        await this.clientRequestsDeleteRaw({ id: id });
    }

    /**
     */
    async clientRequestsListRaw(requestParameters: ClientRequestsListRequest): Promise<runtime.ApiResponse<Array<Request>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.isUpcoming !== undefined) {
            queryParameters['is_upcoming'] = requestParameters.isUpcoming;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RequestFromJSON));
    }

    /**
     */
    async clientRequestsList(isUpcoming?: string): Promise<Array<Request>> {
        const response = await this.clientRequestsListRaw({ isUpcoming: isUpcoming });
        return await response.value();
    }

    /**
     */
    async clientRequestsPartialUpdateRaw(requestParameters: ClientRequestsPartialUpdateRequest): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRequestsPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientRequestsPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async clientRequestsPartialUpdate(id: string, data: Request): Promise<Request> {
        const response = await this.clientRequestsPartialUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientRequestsReadRaw(requestParameters: ClientRequestsReadRequest): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRequestsRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async clientRequestsRead(id: string): Promise<Request> {
        const response = await this.clientRequestsReadRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async clientRequestsUpdateRaw(requestParameters: ClientRequestsUpdateRequest): Promise<runtime.ApiResponse<Request>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientRequestsUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientRequestsUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/requests/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RequestToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFromJSON(jsonValue));
    }

    /**
     */
    async clientRequestsUpdate(id: string, data: Request): Promise<Request> {
        const response = await this.clientRequestsUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientSchedulesCreateRaw(requestParameters: ClientSchedulesCreateRequest): Promise<runtime.ApiResponse<Schedule>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSchedulesCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/schedules/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleFromJSON(jsonValue));
    }

    /**
     */
    async clientSchedulesCreate(data: Schedule): Promise<Schedule> {
        const response = await this.clientSchedulesCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientSchedulesDeleteRaw(requestParameters: ClientSchedulesDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSchedulesDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/schedules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clientSchedulesDelete(id: string): Promise<void> {
        await this.clientSchedulesDeleteRaw({ id: id });
    }

    /**
     */
    async clientSchedulesListRaw(): Promise<runtime.ApiResponse<Array<Schedule>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/schedules/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScheduleFromJSON));
    }

    /**
     */
    async clientSchedulesList(): Promise<Array<Schedule>> {
        const response = await this.clientSchedulesListRaw();
        return await response.value();
    }

    /**
     */
    async clientSchedulesPartialUpdateRaw(requestParameters: ClientSchedulesPartialUpdateRequest): Promise<runtime.ApiResponse<Schedule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSchedulesPartialUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSchedulesPartialUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/schedules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleFromJSON(jsonValue));
    }

    /**
     */
    async clientSchedulesPartialUpdate(id: string, data: Schedule): Promise<Schedule> {
        const response = await this.clientSchedulesPartialUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     */
    async clientSchedulesReadRaw(requestParameters: ClientSchedulesReadRequest): Promise<runtime.ApiResponse<Schedule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSchedulesRead.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/schedules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleFromJSON(jsonValue));
    }

    /**
     */
    async clientSchedulesRead(id: string): Promise<Schedule> {
        const response = await this.clientSchedulesReadRaw({ id: id });
        return await response.value();
    }

    /**
     */
    async clientSchedulesUpdateRaw(requestParameters: ClientSchedulesUpdateRequest): Promise<runtime.ApiResponse<Schedule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clientSchedulesUpdate.');
        }

        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSchedulesUpdate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/schedules/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleFromJSON(jsonValue));
    }

    /**
     */
    async clientSchedulesUpdate(id: string, data: Schedule): Promise<Schedule> {
        const response = await this.clientSchedulesUpdateRaw({ id: id, data: data });
        return await response.value();
    }

    /**
     * Retrieve a list of services for items with prices.
     * PUBLIC METHOD.
     */
    async clientServicesListRaw(): Promise<runtime.ApiResponse<Array<Service>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/client/services/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceFromJSON));
    }

    /**
     * Retrieve a list of services for items with prices.
     * PUBLIC METHOD.
     */
    async clientServicesList(): Promise<Array<Service>> {
        const response = await this.clientServicesListRaw();
        return await response.value();
    }

    /**
     * Twilio `Make HTTP Request` widget status logic: - 200 or 204 (success) - 3xx (redirect) - 4xx or 5xx (fail)
     */
    async clientSmsDeliveryCreateRaw(requestParameters: ClientSmsDeliveryCreateRequest): Promise<runtime.ApiResponse<TwilioFlexDeliveryWebhook>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSmsDeliveryCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/sms/delivery/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwilioFlexDeliveryWebhookToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TwilioFlexDeliveryWebhookFromJSON(jsonValue));
    }

    /**
     * Twilio `Make HTTP Request` widget status logic: - 200 or 204 (success) - 3xx (redirect) - 4xx or 5xx (fail)
     */
    async clientSmsDeliveryCreate(data: TwilioFlexDeliveryWebhook): Promise<TwilioFlexDeliveryWebhook> {
        const response = await this.clientSmsDeliveryCreateRaw({ data: data });
        return await response.value();
    }

    /**
     * Twilio `Make HTTP Request` widget status logic: - 200 or 204 (success) - 3xx (redirect) - 4xx or 5xx (fail)
     */
    async clientSmsOnlineWorkersCreateRaw(requestParameters: ClientSmsOnlineWorkersCreateRequest): Promise<runtime.ApiResponse<TwilioFlexOnlineWorkersWebhook>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSmsOnlineWorkersCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/client/sms/online_workers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TwilioFlexOnlineWorkersWebhookToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TwilioFlexOnlineWorkersWebhookFromJSON(jsonValue));
    }

    /**
     * Twilio `Make HTTP Request` widget status logic: - 200 or 204 (success) - 3xx (redirect) - 4xx or 5xx (fail)
     */
    async clientSmsOnlineWorkersCreate(data: TwilioFlexOnlineWorkersWebhook): Promise<TwilioFlexOnlineWorkersWebhook> {
        const response = await this.clientSmsOnlineWorkersCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientSubscriptionCheckoutCreateRaw(requestParameters: ClientSubscriptionCheckoutCreateRequest): Promise<runtime.ApiResponse<SubscriptionChooseResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSubscriptionCheckoutCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/subscription/checkout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionCheckoutToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionChooseResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientSubscriptionCheckoutCreate(data: SubscriptionCheckout): Promise<SubscriptionChooseResponse> {
        const response = await this.clientSubscriptionCheckoutCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientSubscriptionChooseCreateRaw(requestParameters: ClientSubscriptionChooseCreateRequest): Promise<runtime.ApiResponse<SubscriptionChooseResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientSubscriptionChooseCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/subscription/choose/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionChooseToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionChooseResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientSubscriptionChooseCreate(data: SubscriptionChoose): Promise<SubscriptionChooseResponse> {
        const response = await this.clientSubscriptionChooseCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientWelcomeCheckoutCreateRaw(requestParameters: ClientWelcomeCheckoutCreateRequest): Promise<runtime.ApiResponse<WelcomeCheckoutResponse>> {
        if (requestParameters.data === null || requestParameters.data === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling clientWelcomeCheckoutCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/welcome/checkout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WelcomeCheckoutToJSON(requestParameters.data),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WelcomeCheckoutResponseFromJSON(jsonValue));
    }

    /**
     */
    async clientWelcomeCheckoutCreate(data: WelcomeCheckout): Promise<WelcomeCheckoutResponse> {
        const response = await this.clientWelcomeCheckoutCreateRaw({ data: data });
        return await response.value();
    }

    /**
     */
    async clientZipCodesListRaw(): Promise<runtime.ApiResponse<Array<ZipCode>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/client/zip_codes/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ZipCodeFromJSON));
    }

    /**
     */
    async clientZipCodesList(): Promise<Array<ZipCode>> {
        const response = await this.clientZipCodesListRaw();
        return await response.value();
    }

    /**
     */
    async clientGetOrderPdf(requestParameters: GetClientOrderPdf) {
        if (requestParameters.order_id === null || requestParameters.order_id === undefined) {
            throw new runtime.RequiredError('data','Required parameter requestParameters.data was null or undefined when calling getPDF.');
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const formData = new URLSearchParams();
        formData.append('order_id', requestParameters.order_id);


        const response = await this.request({
            path: `/client/orders/${requestParameters.order_id}/`,
            method: 'POST',
            headers: headerParameters,
            body: formData,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue);
        // return response;
    }

}
