import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Header } from 'components/Header';
import { useAuth } from 'hooks';

import Intro from 'pages/LeatherCare/Intro/Intro';
import { HIW } from './HIW/HIW';

import Sheduling from 'pages/LeatherCare/Sheduling/Sheduling';
import { AreaRugFAQ } from './AreaRugFAQ/AreaRugFAQ';
import InputArea from 'components/PricingNew/InputArea/InputArea';

import { Colorizer, PaddingRemover, RugBr, SchedulingWrapper, Wrapper } from './AreaRug.style';
import { BgRemover, MainContainer, NavbarWrapper, RedesignInputArea } from 'pages/LeatherCare/styles';
import { CenteredTextWrapper, IntroWrapper } from 'pages/Preservation/Preservation.style';

import IntroImage from '../../assets/new-ui/area-rug/RugIntroBackground-min.png';

const LazySteps = React.lazy(() => import('./Steps/Steps'));
const LazyPickTheBest = React.lazy(() => import('components/PricingNew/PickTheBest/PickTheBest'));
const LazyAreaRugCalculator = React.lazy(() => import('./AreaRugCalculator/AreaRugCalculator'));
const LazyHaveMoreQuestion = React.lazy(() => import('components/PricingNew/HaveMoreQuestions/HaveMoreQuestions'));

const AreaRugPage = ({ flag }) => {
  const { user, logout } = useAuth();
  const [navbarColor, setNavbarColor] = useState('RGBA(255,255,255, 0.4)');
  const [navbarLinksColor, setNavbarLinksColor] = useState('white');
  useEffect(() => {
    if (flag && navbarColor !== 'white') {
      setNavbarColor('white');
      setNavbarLinksColor('#003459');
    }
    if (!flag && navbarColor !== 'RGBA(255,255,255, 0.4)') {
      setNavbarColor('RGBA(255,255,255, 0.4)');
      setNavbarLinksColor('white');
    }
  }, [flag, navbarColor]);
  return (
    <>
      <Helmet>
        <title>Washmix - Area Rug</title>
        <meta name="description" content="Area Rug" />
      </Helmet>
      <NavbarWrapper bg={navbarColor} linksColor={navbarLinksColor}>
        <Header logout={logout} user={user} />
      </NavbarWrapper>
      <MainContainer>
        <PaddingRemover>
          <Intro background={IntroImage}>
            <IntroWrapper>
              <Colorizer color="#F9FBFE">
                <h1>
                  We Clean <RugBr /> All Rugs
                </h1>
              </Colorizer>
              <Wrapper>
                <Colorizer color="#F9FBFE">
                  <p>
                    With Home OR Office Pickup & Delivery - Rug Cleaning has newer been made easier. All along with our
                    Exclusive Services with Odor Removal, Spills and treatment of stains and other debris. Professional
                    Rug Cleaning is a crucial step to prolonging the life of your most loved, and in many cases valuable
                    piece of furniture at home.
                  </p>
                </Colorizer>
              </Wrapper>
            </IntroWrapper>
          </Intro>
        </PaddingRemover>
        <HIW />
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazySteps />
        </React.Suspense>
        <BgRemover bg="white">
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyPickTheBest />
          </React.Suspense>
        </BgRemover>
        <BgRemover bg="#f0f5f8">
          <CenteredTextWrapper>
            <h1>Prices per Sq Ft</h1>
          </CenteredTextWrapper>
          <React.Suspense fallback={<div>Loading...</div>}>
            <LazyAreaRugCalculator />
          </React.Suspense>
        </BgRemover>
        <InputArea />
        <SchedulingWrapper>
          <Sheduling title="Scheduling" />
        </SchedulingWrapper>
        <AreaRugFAQ />
        <RedesignInputArea>
          <InputArea />
        </RedesignInputArea>
        <React.Suspense fallback={<div>Loading...</div>}>
          <LazyHaveMoreQuestion />
        </React.Suspense>
      </MainContainer>
    </>
  );
};

export default AreaRugPage;
