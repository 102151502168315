/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface POSQuantity
 */
export interface POSQuantity {
    /**
     * 
     * @type {number}
     * @memberof POSQuantity
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof POSQuantity
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    service: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    item: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    readonly amount?: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    readonly dollarAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    readonly discount?: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    readonly dollarDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    readonly amountWithDiscount?: string;
    /**
     * 
     * @type {string}
     * @memberof POSQuantity
     */
    readonly dollarAmountWithDiscount?: string;
}

export function POSQuantityFromJSON(json: any): POSQuantity {
    return POSQuantityFromJSONTyped(json, false);
}

export function POSQuantityFromJSONTyped(json: any, ignoreDiscriminator: boolean): POSQuantity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'count': json['count'],
        'service': json['service'],
        'item': json['item'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'dollarAmount': !exists(json, 'dollar_amount') ? undefined : json['dollar_amount'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'dollarDiscount': !exists(json, 'dollar_discount') ? undefined : json['dollar_discount'],
        'amountWithDiscount': !exists(json, 'amount_with_discount') ? undefined : json['amount_with_discount'],
        'dollarAmountWithDiscount': !exists(json, 'dollar_amount_with_discount') ? undefined : json['dollar_amount_with_discount'],
    };
}

export function POSQuantityToJSON(value?: POSQuantity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'count': value.count,
        'service': value.service,
        'item': value.item,
    };
}


