/* tslint:disable */
/* eslint-disable */
/**
 * WashMix
 * WashMix REST API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TwilioFlexOnlineWorkersWebhook
 */
export interface TwilioFlexOnlineWorkersWebhook {
    /**
     * 
     * @type {string}
     * @memberof TwilioFlexOnlineWorkersWebhook
     */
    sid: string;
}

export function TwilioFlexOnlineWorkersWebhookFromJSON(json: any): TwilioFlexOnlineWorkersWebhook {
    return TwilioFlexOnlineWorkersWebhookFromJSONTyped(json, false);
}

export function TwilioFlexOnlineWorkersWebhookFromJSONTyped(json: any, ignoreDiscriminator: boolean): TwilioFlexOnlineWorkersWebhook {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sid': json['sid'],
    };
}

export function TwilioFlexOnlineWorkersWebhookToJSON(value?: TwilioFlexOnlineWorkersWebhook | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sid': value.sid,
    };
}


