import React from 'react';
import { BigImageWrapper, Container, ImageWrapper, InfoWrapper, TextWrapper } from './Sheduling.styles';

import Computer from '../../../assets/new-ui/wash-and-fold/computer-min.png';
import Check from '../../../assets/new-ui/wash-and-fold/check-blue.svg';
import { CustomBr } from '../Steps/Steps.styles';

const Sheduling = ({ title = 'Scheduling' }) => {
  return (
    <Container>
      <ImageWrapper>
        <img src={Check} alt="check" loading="lazy" />
      </ImageWrapper>
      <h1>{title}</h1>
      <InfoWrapper>
        <BigImageWrapper>
          <img src={Computer} alt="computer" loading="lazy" />
        </BigImageWrapper>
        <TextWrapper>
          <h2>
            Scedule With <br />a TEXT Message
          </h2>
          <p>
            After you establish your account online and set <br /> your preferences. You <strong>No Longer</strong> need
            to login! <br /> Simply <strong>Text</strong> &quot;pickup&quot; <CustomBr sm />
            <strong>to 415-993-WASH</strong> from your <br /> Registered Cell Phone.
          </p>
        </TextWrapper>
      </InfoWrapper>
    </Container>
  );
};

export default Sheduling;
